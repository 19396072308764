import cardReturn from "../Battle/data/cardReturn"

export default function Chest(chest){
    let ppotion =Math.floor(Math.random()*15)
    let hpotion = Math.floor(Math.random()*40)

    let prize
    let locate = "items.buffs"
    let gem = 0
    
    if(chest==="orange"){
        let ifCard = Math.floor(Math.random()*3) === 0;
        if(ifCard) {
            let ran = Math.floor(Math.random()*74)
            locate = "items.cards"
            if(ran===0)prize=cardReturn("Wizzard")
            else if(ran===1)prize=cardReturn("Dragon")
            else if(ran<=3) prize=cardReturn("Golem")
            else if(ran<=5) prize=cardReturn("Mage")
            else if(ran<=7) prize=cardReturn("Necro")
            else if(ran<=11)prize=cardReturn("Elekt")
            else if(ran<=15)prize=cardReturn("Ghost")
            else if(ran<=19)prize=cardReturn("Therapist")
            else if(ran<=27)prize=cardReturn("Gurl")
            else if(ran<=35)prize=cardReturn("Aqua")
            else if(ran<=43)prize=cardReturn("Witch")
            else if(ran<=58)prize=cardReturn("Zombie")
            else if(ran<=73) prize=cardReturn("Boi")
        } else {
            let ran = Math.floor(Math.random()*38)
            if(ran===0) {prize={name: "20 Gems"};locate = "point.gem";gem=20}
            else if(ran===1) prize={name: "Health Potion",fname:"Epic",power: 240+hpotion, type: "HP"}
            else if(ran===2) prize={name: "Power Potion",fname:"Epic",power: 90+ppotion, type: "AP"}
            else if(ran===3) prize={name: "Lucky Charm",fname:"Epic",power: 30, type: "Luck"}
            else if(ran===4){prize={name:"Oblivion",fname:"Book of",power: null, type: "Reset"}}
            else if(ran<=7) {prize={name: "Health Potion",fname:"Rare",power: 160+hpotion, type: "HP"}}
            else if(ran<=10){prize={name:"Power Potion",fname:"Rare",power: 60+ppotion, type: "AP"}}
            else if(ran<=13){prize={name:"Lucky Charm",fname:"Rare",power: 20, type: "Luck"}}
            else if(ran<=19){prize={name:"Health Potion",fname:"Common",power: 80+hpotion, type: "HP"}}
            else if(ran<=25){prize={name:"Power Potion",fname:"Common",power: 30+ppotion, type: "AP"}}
            else if(ran<=31){prize={name:"Lucky Charm",fname:"Common",power: 10, type: "Luck"}}
            else if(ran<=37){prize={name: "3 Gems"};locate = "point.gem";gem=3}
        }
    } else if (chest==="pink"){
        let ifCard = Math.floor(Math.random()*5) < 2;
        if(ifCard) {
            locate = "items.cards"
            let ran = Math.floor(Math.random()*44)
            if(ran===0)prize=cardReturn("Wizzard")
            else if(ran===1)prize=cardReturn("Dragon")
            else if(ran<=3)prize=cardReturn("Golem")
            else if(ran<=5)prize=cardReturn("Mage")
            else if(ran<=7)prize=cardReturn("Necro")
            else if(ran<=11)prize=cardReturn("Ghost")
            else if(ran<=15)prize=cardReturn("Therapist")
            else if(ran<=19)prize=cardReturn("Elekt")
            else if(ran<=27)prize=cardReturn("Gurl")
            else if(ran<=35)prize=cardReturn("Aqua")
            else if(ran<=43)prize=cardReturn("Witch")
        } else {
            let ran = Math.floor(Math.random()*34)
            if(ran<=1)prize={name:"Health Potion",fname:"Mystic",power: 320+hpotion, type: "HP"}
            else if(ran<=2)prize={name:"Power Potion",fname:"Mystic",power: 120+ppotion, type: "AP"}
            else if(ran<=3)prize={name:"Lucky Charm",fname:"Mystic",power: 40, type: "Luck"}
            else if(ran<=6)prize={name:"Health Potion",fname:"Epic",power: 240+hpotion, type: "HP"}
            else if(ran<=9)prize={name:"Power Potion",fname:"Epic",power: 90+ppotion, type: "AP"}
            else if(ran<=12)prize={name:"Lucky Charm",fname:"Epic",power: 30, type: "Luck"}
            else if(ran<=18)prize={name:"Health Potion",fname:"Rare",power: 160+hpotion, type: "HP"}
            else if(ran<=24)prize={name:"Power Potion",fname:"Rare",power: 60+ppotion, type: "AP"}
            else if(ran<=30)prize={name:"Lucky Charm",fname:"Rare",power: 20, type: "Luck"}
            else if(ran<=33) prize={name:"Oblivion",fname:"Book of",power: null, type: "Reset"}
        }
    } else {
        let ran = Math.floor(Math.random()*400)
        locate = "items.collection"
        if(ran<=30)prize= { name: "Sofa" }
        else if(ran<=60)prize= { name: "Drawers" }
        else if(ran<=90)prize= { name: "Chair" }
        else if(ran<=120)prize= { name: "Plant" }
        else if(ran<=140)prize= { name: "Window" }
        else if(ran<=160)prize= { name: "Books" }
        else if(ran<=180)prize= { name: "TV" }
        else {
            let r1 = Math.floor(Math.random()*150)
            let r2 = Math.floor(Math.random()*150)
            let r3 = Math.floor(Math.random()*150)
            prize= { name: "Wall Color", value: `rgb(${255 - r1}, ${255 -r2}, ${255 - r3})` }
        }
    }
    console.log(prize)
    return [prize,locate,gem]
}
export default{
    cancel: "キャンセル",
    //Header
    quest: "クエスト",
    shop: "ショップ",
    account: "アカウント",
    logout: "ログアウト",
    chat: "チャット",
    closet: "クローゼット",
    inventory: "持ち物",
    battle: "バトル",
    work2: "仕事",
    login: "ログイン",
    register: "登録する",

    //Setting
    email:"メールアドレス",
    password: "パスワード",
    disname: "表示名",
    payinfo: "お支払方法",
    nopayinfo: "なし",
    language: "言語",
    region:"地域",
    accoinfo: "アカウント情報",
    profinfo: "プロフィール",
    save: "保存",
    share: "共有",
    //Task
    edit: "編集",
    delete: "削除",
    work: "出勤!",
    tasks: "タスク",
    title: "タイトル",
    description: "内容",
    priority: "重要度",
    create: "作成",
    High: "高",
    Medium: "中",
    Low: "低",
    //Work
    task: "タスク",
    youareworking: "仕事中",
    enjoybreak: "休憩楽しんで！",
    goodjob: "お疲れ様です。今日の仕事を評価しましょう！",
    rest: "休憩をとる",
    rework: "仕事に戻る",
    finish: "退勤!",
    review: "反省会",
    confirm: "確認",
    readytowork: "仕事の準備ＯＫ？",
    dayoff: "今日は休む",
    seeyou: "また明日！",
    messagefromlastday: "前日のあなたからのメッセージ",
    scroll: "スクロールしてもっと見る",
    productivehistory: "過去の働いた時間",
    totalworktime: "Total Work Time",
    resttime: "Break Time",
    totalresttime: "Total Break Time",
    //Top
    profile: "プロフィール",
    friends: "友達",
    leaderboard: "ランキング",
    //Quest
    dailyquest: "毎日クエスト",
    weeklyquest: "週間クエスト",
    loginquest: "Login to Motimanager",
    workquest: "Get to work",
    hoursquest: "Work for more than 3 hours",
    hours2quest: "Work for more than 6 hours",
    reviewquest: "Review today's work",
    restquest: "Take a day off",
    rest2quest: "Take another day off",
    favquest: "Do your favorite thing",
    //Battle
    choosecard: "カードを選択",
    challenger: "挑戦者",
    boss: "ボス"
    
}
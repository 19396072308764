import React from "react"
import { Col, Row, Image } from "react-bootstrap"

const House = ({ profile, box, custom, setCustom }) => {

    return (
        <div className="p-3">
            <h2>{profile.disname}'s House</h2>
            <br/>
            <div style={{ backgroundColor: custom.background, width:"100%",  borderRadius: "10px", maxWidth: "800px", margin:"0 auto", position: "relative" }}>
                <div style={{
                    position: "absolute", bottom: 0, backgroundColor: "rgb(193,154,107)", height: "8vw", maxHeight: "70px", width: "100%", borderTop: "solid 3px rgb(100,100,100)" 
                }} />
                <Row style={{ verticalAlign: "bottom"}}>
                    <Col>
                        <Image className="w-100" style={{maxWidth:"300px" }} src={`/images/cards/${custom.icon}.png`}/>
                    </Col>
                    <Col>
                        {custom.furnitures[0] ? (
                            <Image className="w-100" src={`/images/cards/${custom.furnitures[0]}.png`}/>
                        ) : null}
                    </Col>
                </Row>
            </div>
            <br/><br/>
            <div>
                <h2>Furnitures</h2>
                {box.furnitures.map((fur,key)=>{
                    if(fur) return <Image className="hoverimage" key={key} src={`/images/cards/${fur}.png`}
                    style={{ cursor: "pointer", maxWidth:"200px", opacity: fur === custom.furnitures[0] ? 0.3 : 1 }}
                    onClick={()=> setCustom({ ...custom, furnitures: [fur] })} />
                })}
                <h2>Wall Colors</h2>
                <div className="d-flex flex-wrap" style={{ justifyContent: "center"}} >
                {box.backgrounds.map((fur,key)=>{
                    if(fur) return (
                    <div 
                    key={key}
                    className="hoverimage" 
                    style={{ width:"110px", height:"110px", background: fur, margin: "15px", cursor: "pointer", opacity: fur === custom.background ? 0.3 : 1 }}
                    onClick={()=> setCustom({ ...custom, background: fur })} 
                    />
                    )
                })}
                </div>
            </div>
        </div>
    )
}


export default House
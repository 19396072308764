import React, { useEffect, useState } from "react";
import axios from "axios";
import { injectIntl, FormattedMessage } from "react-intl"
import { Row, Col, Button, Spinner } from "react-bootstrap"

import api from "../../api"

import Quest from "./Quest"
import ReviewModal from "./Module/ReviewModal"
import AlertModal from "./Module/AlertModal"
import TaskList from "./TaskList";

var myInt
var myInt2

const Work = props => {
  const [status, setStatus] = useState(null)
  const [workTime, setWorkTime] = useState(0)
  const [restTime, setRestTime] = useState(0)
  const [count, setCount] = useState(undefined)
  const [count2, setCount2] = useState(undefined)
  const [workhours, setWorkhours] = useState(null)
  const [dayoff, setDayoff] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [workModalShow, setWorkModalShow] = useState(false)
  const [modalCoin, setModalCoin] = useState(false)

  useEffect(()=>{
    setStatus("before")
    return(()=>{
      clearInterval(myInt)
      clearInterval(myInt2)
    })
  },[])

  const timeCount =(time,ifRest)=>{
    let d = time
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    if(ifRest) setCount2({ hour: h, minute: m, second: s });
    else setCount({hour: h, minute: m, second: s});

    clearInterval(myInt)
    myInt = setInterval(()=>{
      s++
      if(s===60){
        m++
        s=0
      }
      if(m===60){
        h++
        m=0
      }
      d++;
      if(ifRest) {
        setCount2({hour: h, minute: m, second: s})
        setRestTime(d);
      } else {
        setCount({hour: h, minute: m, second: s})
        setWorkTime(d);
      }
    },1000)
  }

  const work = state =>{
    let info = { username: props.user.username, work:state }
    props.update("status", state);
    switch (state){
      case "work": {
        timeCount(0)
        break;
      }
      case "finish": {
        clearInterval(myInt)
        clearInterval(myInt2)
        review()
        break;
      }
      default: break;
    }
    setStatus(state)
  }

  const review =()=> {
    let info = { username: props.user.username }
    setWorkhours({ work: count, rest: count2 })
    setWorkModalShow(true)

    return;
    axios.post(api+"/users/getdata", info).then(res=>{
      let d = res.data.hours.work
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let d2 = res.data.hours.rest
      let h2 = Math.floor(d2 / 3600);
      let m2= Math.floor(d2 % 3600 / 60);
      setWorkhours({hour: h, minute: m, start: res.data.hours.start, end:res.data.hours.end, resthour: h2, restminute: m2})
    })
  }

  const reviewcomplete = data =>{
    if(data.grade){
      setStatus("after")
      setWorkModalShow(false)
      let min = Math.round(workhours.minute / 6)
      min = min / 10
      let coinGet = (workhours.hour * 60 + workhours.minute) * 10;
      if (!coinGet) coinGet = 10;
      let coinNew = props.point.coin + coinGet
      let info = {
        message: data.comment, hours: workhours.hour+min, grade: data.grade, username: props.user.username,
        historylength: props.user.history.length, coins: coinNew, total: props.user.point.total + workhours.hour
      }
      axios.post(api+"/users/review", info)
      props.claim({ point: { coin: coinNew, gem: props.point.gem, rank: props.point.rank } })
      setModalShow(true)
      setModalCoin(coinGet)
    }
  }

  const takeDayoff=()=>{
    setModalShow(false)
    let info = { username: props.user.username }
    axios.post(api+"/users/dayoff", info)
    setStatus("after")
  }

  return (
    <div className="inside-container">
      <div className="text-center work-container">
        <Row className="m-0">
          <Col className="p-1" style={{minWidth:"60%"}}>
            <div className="m-auto">
              <div className="card-round mb-4">
              <div className="card-top">
                Work Timer
              </div>
              <div className="card-bottom">
                {status?
                  <h3 className="font-weight-bold text-dark pt-3">
                    <FormattedMessage 
                    id={status==="before"?"readytowork":status==="work"?"youareworking":status==="rest"?"enjoybreak":status==="finish"?"goodjob":"seeyou"}
                    />
                  </h3>
                :null}
                {count?
                  <div>
                    {count.loading?
                    <Spinner style={{width:"120px",height:"120px",marginBottom:"15px"}} animation="grow" variant="dark"/>
                    : status === "work" || status === "rest" ?
                    <div className="mt-3 mb-2">
                      <h5 className="mt-2">
                        <FormattedMessage id={status==="work"?"totalworktime":"resttime"}/>
                      </h5>
                      <h1 className="container-round font-weight-bold">
                        {count.hour}:{count.minute<10?"0":null}{count.minute}:{count.second<10?"0":null}{count.second}
                      </h1>
                      {status==="rest"?
                        <div className="pt-2 pb-1">
                          <h6>Total Break Time: </h6>
                          <h2 className="container-round-small font-weight-bold">
                            {count2.hour}:{count2.minute<10?"0":null}{count2.minute}:{count2.second<10?"0":null}{count2.second}
                          </h2>
                        </div>
                      :null}
                    </div>
                    : null}
                  </div>
                :null}
                <div className="pt-3 pb-4">
                {status === null ? 
                <div>
                  <Spinner style={{width:"120px",height:"120px",marginBottom:"15px"}} animation="grow" variant="dark" />
                </div>
                :
                status === "before"?
                  <div>
                    <button className="bgc-blue btn-hover btn m-1" onClick={()=>work("work")}>
                      <FormattedMessage id="work" />
                    </button>
                    <button
                      className="btn bgc-green btn-hover m-1"
                      variant="success"
                      onClick={()=>{
                        setDayoff(true)
                        setModalShow(true)
                      }}>
                      <FormattedMessage id="dayoff"/>
                    </button>
                  </div>
                :status==="work"?
                <div>
                  <Button
                    className="m-1"
                    variant="success"
                    onClick={()=>{
                      clearInterval(myInt)
                      clearInterval(myInt2)
                      //setCount({ loading: true })
                      setStatus("rest");
                      timeCount(restTime, true);
                      return;
                      axios.post(api+"/users/work",{username: props.user.username, work: "rest"}).then(res=>{
                        timeCount(0,res.data.rest)
                        setStatus("rest")
                      })
                    }}
                  >
                    <FormattedMessage id="rest"/>
                  </Button>
                  <Button className=" m-1" onClick={()=>work("finish")}>
                    <FormattedMessage id="finish"/>
                  </Button>
                </div>
                :status==="rest"?
                <div>
                  <Button
                    className=" m-1"
                    onClick={()=>{
                      clearInterval(myInt)
                      clearInterval(myInt2)
                      timeCount(workTime, false);
                      setStatus("work");
                      // axios.post(api+"/users/work",{username:props.user.username, work: "work"}).then(res=>{
                      //   timeCount(res.data.count)
                      //   setStatus("work")
                      // })
                    }}
                  >
                    <FormattedMessage id="rework"/>
                  </Button>
                </div>
                :status==="finish"?
                <div>
                <Button onClick={review}>
                  <FormattedMessage id="review"/>
                </Button>
                </div>
                :<a href="/play"><button className="btn btn-hover bgc-blue">Go Play!</button></a>}
                </div>
              </div>
              </div>
              {status !== null ?<TaskList
                chatUpdate={props.chatUpdate}
                username={props.user.username}
                friends={props.friends}
                sendMessage={props.sendMessage}
                addTask={props.addTask}
                empty={props.empty}
              />:null}
            </div>
          </Col>
          <Col className="card-quest">
            <Quest
              claim={prop=>{
                props.claim(prop)
              }}
              point={props.point}
              user={props.user}
            />
          </Col>
        </Row>
        <AlertModal
          show={modalShow}
          onHide={()=> setModalShow(false)}
          dayoff={dayoff}
          takeDayoff={takeDayoff}
          coin={modalCoin}
        />
        <ReviewModal
          show={workModalShow}
          onHide={()=> setWorkModalShow(false)}
          workhours={workhours}
          reviewcomplete={reviewcomplete}
        />
      </div>
    </div>
  );
}

export default injectIntl(Work);
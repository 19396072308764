import React from "react"
import { FormattedMessage } from "react-intl";
import{ Modal, Button } from "react-bootstrap"
import CardUI from "../Battle/CardUI";

export default function PickCard({ cards, buffs, withBuff, both, edit, show, onHide, editNum, selected }) {
  return (
    <Modal
    show={show}
    onHide={onHide}
      size="xl"
      centered
      className="text-center"
    >
        <div className="play-bg" style={{ color:"white", padding:"20px", fontFamily:"Montserrat" }}>
          <h3>
            {both?
              `Choose an item`
            :
              <FormattedMessage id="choosecard"/>
            }
          </h3>   
          <p/>
          <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: "center" }}>
            {!both? cards.map((card,key)=>{
              if(!selected || !selected.cards[key]) return <div key={key} className="pb-4" style={{ width: "200px" }}>
                <div className="hoverimage card-game"
                  onClick={()=>{
                    onHide()
                    edit(key,editNum)
                  }}>
                  <CardUI card={card} />
                </div>
              </div>
              else return ""
            }):null}
            {withBuff? buffs.map((buff,key)=>{
              if(!selected || !selected.buffs[key]) return <div key={key} className="pb-4" style={{ width: "200px" }}>
                <div className="hoverimage card-game"
                style={{ margin: "auto", marginTop: "auto" }}
                  onClick={()=>{
                    onHide()
                    edit(key, editNum, true)
                  }}>
                  <CardUI card={buff} buff={true} />
                </div>
              </div>
              else return ""
            }) :null}
          </div>
          <div>
            <Button variant="danger" onClick={onHide}>Cancel</Button>
          </div>
        </div>
    </Modal>
  );
}
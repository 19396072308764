import cardReturn from "../Battle/data/cardReturn"

export default function chanceReturn(arr){
    let chance = 0
    let cost = 3
    var next = arr[0].item
    let type =  0
    let impossible = false


    if(arr[0] && arr[1]){
        if(arr[0].item.fname===undefined){
            next = {name:next.name,hp:next.hp,ap:next.ap,ap2:next.ap2,cost:next.cost,equip:next.equip,buff:next.buff+1}
            if(arr[1].item.name!=="Lucky Charm"&&(arr[1].item.name==="Power Potion"||arr[1].item.name==="Health Potion"||arr[1].item.name==="Oblivion")){
                if(arr[1].item.name==="Health Potion"){
                    next.hp+=arr[1].item.power
                } else if(arr[1].item.name==="Power Potion"){
                    next.ap+=arr[1].item.power
                    next.ap2+=arr[1].item.power
                }
                if(arr[1].item.name==="Oblivion"){
                    chance=100
                    cost=0
                    
                    next = cardReturn(arr[0].item.name)
                    next.equip=arr[0].item.equip
                } else{
                    switch(arr[0].item.buff){
                        case 0: chance+=100;cost=2;break;
                        case 1: chance+=80;cost=5;break;
                        case 2: chance+=60;cost=10;break;
                        case 3: chance+=40;cost=15;break;
                        case 4: chance+=20;cost=20;break;
                        case 5: chance+=10;cost=25;break;
                        case 6: chance+=5;cost=30;break;
                        case 7: chance+=0;cost=30;break;
                        case 8: chance+=-5;cost=30;break;
                        case 9: impossible=true;break;
                        default: break;
                    }
                }
                if(arr[2]){
                   if(arr[2].item.name==="Lucky Charm"){
                       chance+=arr[2].item.power
                   } else impossible=true
                }
            }
        } else if(arr[0].item.name===arr[1].item.name&&arr[0].item.fname===arr[1].item.fname){
            next = {name:next.name,fname:next.fname,power:next.power,type:next.type}
            type=1
            let apran = Math.floor(Math.random()*15)
            let hpran = Math.floor(Math.random()*40)
            let num = 1
            switch(arr[0].item.fname){
                case "Common": chance+=80;num*=20;next.fname="Rare";break;
                case "Rare": chance+=60;num*=30; cost=6;next.fname="Epic";break;
                case "Epic": chance+=30;num*=40; cost=12;next.fname="Mystic";break;
                case "Mystic": chance+=10;num*=60; cost=25;next.fname="Legendary";break;
                case "Legendary": impossible=true;break;
                default: break;
            }
            switch(arr[0].item.name){
                case "Power Potion": next.power = num*3+apran;break;
                case "Health Potion": next.power = num*8+hpran;break;
                case "Lucky Charm": break;
                default: break;
            }
            if(arr[2]){
                if(arr[2].item.name==="Lucky Charm"){
                    chance+=arr[2].item.power
                } else impossible=true
             }
        }
    }
    if(impossible) {chance=0;cost=3}
    return({chance: chance, cost:cost, type: type, next: next})
}
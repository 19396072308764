import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "./Style/style-general.scss"
import "./Style/style-screen.scss"
import "./Style/style-button.scss"
import "./Style/style-color.scss"
//import 'font-awesome/css/font-awesome.min.css'

ReactDOM.render(<App />, document.getElementById('root'));

import React from "react";
import { Container, FormControl, Button, Image, Spinner, Popover } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

var int

function Body1Component(props) {
  const [popo, setPopo] = React.useState(false)
  const [keep, setKeep] = React.useState(false)
  
  return (
    <div className="text-left m-auto resize" style={{maxWidth: "380px"}}>
      <Image className="mb-3" style={{width:"100%"}} alt="icon" src="/icons/motimanager-12.png"/>
      <h4 className="text-right text-light mb-4">Singing up...</h4>
      {!props.done?<div>
        
        <div className="position-relative">
          <label style={props.state.username===""?{fontSize:"23px",top:"-20%"}:{fontSize:"13px",top:"-27%",paddingLeft:"12px"}}>Email</label>
          <FormControl
            type="email"
            value={props.state.username}
            name="username"
            onChange={props.changeuser}
            autoComplete="off"
            style={{backgroundColor: `rgb(${props.state.mailcolor})`, marginBottom: "20px",
            paddingTop:props.state.username===""?"0":"20px",height:"50px",fontSize:"18px"}}
          />
        </div>
        <div className="position-relative">
          <label style={props.state.password===""?{fontSize:"23px",top:"-20%"}:{fontSize:"13px",top:"-27%",paddingLeft:"12px"}}>Password</label>
            <FontAwesomeIcon icon={faQuestionCircle} size="lg" className="hint" onClick={()=>{
              clearTimeout(int)
              setPopo(!popo)
              if(!popo) int = setTimeout(()=>setPopo(false),5000)
              }}/>
            {popo?
            <Popover placement="bottom" style={{top:"100%",left:"1%"}}>
            <Popover.Content as="h6">
              Your password has to include at least one number, letter and more than 5 digits total.
            </Popover.Content>
            </Popover>
            :null}
        <FormControl
          type="password"
          value={props.state.password}
          name="password"
          onChange={props.changeuser}
          style={{backgroundColor: `rgb(${props.state.pswcolor})`, marginBottom: "20px",
          paddingTop:props.state.password===""?"0":"20px",height:"50px",fontSize:"18px"}}
        />
        </div>
        <div className="position-relative">
          <label style={props.state.repassword===""?{fontSize:"23px",top:"-20%"}:{fontSize:"13px",top:"-27%",paddingLeft:"12px"}}>Re-Enter Password</label>
          <FormControl
            type="password"
            value={props.state.repassword}
            name="repassword"
            onChange={props.changeuser}
            style={{backgroundColor: `rgb(${props.state.repswcolor})`, marginBottom: "20px",
            paddingTop:props.state.repassword===""?"0":"20px",height:"50px",fontSize:"18px"}}
          />
        </div>
        <div className="position-relative">
          <label style={props.state.disname===""?{fontSize:"23px",top:"-20%"}:{fontSize:"12px",top:"-27%",paddingLeft:"12px"}}>Display Name</label>
          <FormControl
            type="text"
            value={props.state.disname}
            name="disname"
            onChange={props.changeuser}
            autoComplete="off"
            style={{marginBottom: "20px",
            paddingTop:props.state.disname===""?"0":"20px",height:"50px",fontSize:"18px"}}
          />
        </div>
        <div className="position-relative" style={{height:"35px", color:"white", paddingLeft:"15px"}}>
                  <FontAwesomeIcon
                    icon={keep?faCheckCircle:faCircle}
                    className="checkmark"
                    onClick={()=>setKeep(!keep)}
                  />
                  <div style={{cursor:"pointer",position:"absolute",left:"15%",top:"-5%"}} onClick={()=>setKeep(!keep)}>
                    I read the <a style={{color:"white",textDecoration:"underline"}}
                    target="blank"
                    href="https://www.privacypolicygenerator.info/live.php?token=IhKvgapGWtltCAhrzPYBzCYVCO3SgPvH">data privacy policy.</a>
                  </div>
          </div>
        <div style={{height:"35px"}}>
        {props.making?
        <div className="text-center"><Spinner variant="primary" animation="border"/></div>:
        <button onClick={()=>{
          if(keep) props.confirm(true)
          else props.confirm(false)
        }}
        className="btn-green">
          Sign Up
        </button>}
        </div>
        <Container className="text-center mb-3">
          <h4 style={{color:"white",paddingTop:"16px"}}>{props.state.incomplete}</h4>
          <Link className="customLink2" to="/" >Already have an Account?</Link>
        </Container>
      </div>:
      <div className="text-center">
      <h5 className="mb-5">Registration Complete!</h5>
            <Button variant="light" href="/" style={{fontSize:"20px",width:"100%"}}>
                Log In Now
            </Button>
      </div>}
    </div>
  );
}

export default Body1Component;
export default{
    cancel: "Cancel",
    //Header
    quest: "Suche",
    shop: "Geschäft",
    account: "Konto",
    logout: "Ausloggen",
    chat: "Chat",
    closet: "クローゼット",
    inventory: "Inventar",
    battle: "Arena",
    work2: "Arbeit",
    login: "Anmeldung",
    register: "Registrieren",
    //Setting
    email:"Email",
    password: "Passwort",
    disname: "Anzeigename",
    payinfo: "Zahlungsmethode",
    nopayinfo: "Keine",
    language: "Sprache",
    region: "Region",
    accoinfo: "Your Account",
    profinfo: "Your Profile",
    save: "Save",
    share: "Teilen",
    //Task
    task: "Task",
    edit: "Bearbeiten",
    delete: "Löschen",
    work: "Arbeit!",
    tasks: "Aufgaben",
    title: "Titel",
    description: "Beschreibung",
    priority: "Priorität",
    create: "Erstellen",
    High: "Hoch",
    Medium: "Normal",
    Low: "Niedrig",
    //Work
    youareworking: "Arbeiten",
    enjoybreak: "Genieße deine Pause!",
    goodjob: "Gut gemacht, Jetzt bewerten!",
    rest: "Mach eine Pause",
    rework: "Zur Arbeit zurückkehren",
    finish: "Fertig!",
    review: "Rezension",
    confirm: "Bestätigen",
    readytowork: "Bereit zu arbeiten?",
    dayoff: "Einen Tag frei nehmen",
    seeyou: "Bis morgen!",
    messagefromlastday: "Nachricht von Ihnen am letzten Tag!",
    scroll: "Scrollen um mehr zu sehen",
    productivehistory: "Produktive Geschichte",
    totalworktime: "Total Work Time",
    resttime: "Break Time",
    totalresttime: "Total Break Time",
    //Top
    profile: "Profile",
    friends: "Friends",
    leaderboard: "Rangliste",
    //Quest
    dailyquest: "Tägliche Suche",
    weeklyquest: "Wöchentliche Suche",
    loginquest: "Login to Motimanager",
    workquest: "Get to work",
    hoursquest: "Work for more than 3 hours",
    hours2quest: "Work for more than 6 hours",
    reviewquest: "Review today's work",
    restquest: "Take a day off",
    rest2quest: "Take another day off",
    favquest: "Do your favorite thing",
    //Battle
    choosecard: "Choose a card",
    challenger: "Challenger",
    boss: "Boss"
}
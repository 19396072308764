import React, { useState } from "react";
import axios from "axios";
import api from "./api"
import io from "socket.io-client"
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl"
import { getLocaleMessages } from "./locale/localization"
import { Spinner } from "react-bootstrap"
import { Decrypt, EncryptObject } from "./Home/Work/Module/TaskDecrypt"

import Login from "./Login/Login";
import Register from "./Register/Register";

import DashBoard from "./Home/DashBoard"
import Work from "./Home/Work/Work";
import Play from "./Home/Play/Play"
import Chat from "./Home/Chat/Chat"
import Setting from "./Home/Setting"
import Guide from "./Guide/Guide"
import DemoAlert from "./Demo/DemoAlert"

import NavbarHome from "./Navbars/NavbarHome";
import NavbarSide from "./Navbars/NavbarSide";
import ServerDown from"./Guide/Serverdown"

import { demoUser, demoChat, demoFriends, demoProfile } from "./Demo/Demo"

var socket
let info = {}

const header = new Headers();
header.append("Set-Cookie", "HttpOnly;Secure;SameSite=Strict");

const App = () => {
  const [render, setRender] = useState(false)
  const [loggedin, setLoggedin] = useState(false)
  const [slow, setSlow] = useState(false)
  const [serverdown, setServerdown] = useState(false)
  const [language, setLanguage] = useState("en")
  const [userData, setUserData] = useState(demoUser)
  const [profile, setProfile] = useState(demoProfile)
  const [chatShow, setChatShow] = useState(false)
  const [friends, setFriends] = useState(demoFriends)
  const [chat, setChat] = useState(demoChat)
  const [ranking, setRanking] = useState([])
  const [rank, setRank] = useState(0)
  const [readcount, setReadcount] = useState(3)
  const [demoAlert, setDemoAlert] = useState(false)
  const [addTask, setAddTask] = useState({})

  if(window.location.pathname === "/demo") {
    document.cookie = "Log-Info=pre-demo; expires=Thu, 01 Jan 2021 00:00:00 UTC; path=/;"
  }

  React.useEffect(()=> {
    //let path = window.location.pathname
    //setTimeout(()=>setSlow(true),8000)
    axios.get(api+"/users").then().catch(()=>setServerdown(true))

    let value = "; " + (document.cookie || "");
    let encrypted = value.split("; UserData=")[1] ? value.split("; UserData=")[1].split(";")[0] : "";
    // Check if it recognizes the user or user is on demo
    if(encrypted){
      const data = Decrypt(encrypted);
      const today = `${new Date().getFullYear()},${new Date().getMonth() + 1},${new Date().getDate()}`;
      let quests = data.quests;
      const newData = { ...data, quests, lastlogin: today }
      if(today !== data.lastlogin && quests) {
        for(const i in quests.daily) delete quests.daily[i].claimed;
        const dayDifference = (new Date().getTime() - new Date(data.lastlogin).getTime()) / (1000 * 60 * 60 * 24);
        if(dayDifference > 6 || new Date(data.lastlogin).getDay() > new Date().getDay()) {
          for(const i in quests.weekly) delete quests.weekly[i].claimed;
        }
        if(newData.point.reward) newData.point.reward.mob = 3;
        const enc = EncryptObject(newData);
        document.cookie = `UserData=${enc}; expires=Thu, 01 Jan ${new Date().getFullYear() + 5} 00:00:00 UTC; path=/;`;
      }

      if(data.chatRead) setReadcount(0);
      setUserData(newData);
      if(newData.profile) setProfile(newData.profile);
      setRender(true);
    } else {
      const data = EncryptObject(demoUser);
      document.cookie = `UserData=${data}; expires=Thu, 01 Jan ${new Date().getFullYear() + 5} 00:00:00 UTC; path=/;`;
      setRender(true)
    }
  },[])

  const getuserdata = username => {
  }

  const chatUpdate = () => {
    axios.post(api+"/friends/check", info).then(res => {
      let readtotal = 0;
      for (let i = 0; i < res.data.chat.length; i++) readtotal = readtotal + res.data.chat[i].read;
      setReadcount(readtotal)
      setChat(res.data.chat)
    })
  }

  const sendMessage = target =>{
    socket.emit("sendMessage", { target: target, msg:"msg" },(error)=>{
      if(error) {
        alert(error);
      }
    })
  }

  const reset = (username, monday) => {
    let account = { username: username, monday: monday }
    axios.post(api+"/users/reset", account, header).then(res=>{
      getuserdata(username)
    })
  }

  const login = tokenToSend => {
    axios.post(api + "/users/login", tokenToSend).then(res => {
      if(res.data === "none"){
        document.cookie = "Log-Info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        setRender(true)
      } else {
        getuserdata(tokenToSend.username)
      }
    }).catch(()=>setRender(true))
  }

  const addPoint = (saved) => {
    let data;
    let point = saved.point || {};

    data = { ...userData, ...saved, point: { ...userData.point, ...point } };
    setUserData(data);
    const enc = EncryptObject(data);
    document.cookie = `UserData=${enc}; expires=Thu, 01 Jan ${new Date().getFullYear() + 5} 00:00:00 UTC; path=/;`;
  }
  
  return (
    <>
      <IntlProvider
        locale={language}
        messages={getLocaleMessages(language)}
      >
        {render ?
          <div className="main">
            <Router>
            <Switch>
              <Route
                path="/"
                exact
                render={()=>
                  loggedin?
                  <DashBoard
                    user={userData}
                    profile={profile}
                    friends={friends}
                    rank={rank}
                    ranking={ranking}
                  />
                  :
                  <Login reset={reset} login={login} />
                }
              />
              <Route
                path="/register"
                exact
                render={() => !loggedin? <Register />:
                <Redirect to='/'/>}
              />
              <Route>
                <div>
                  <NavbarHome
                  read={readcount}
                  user={userData}
                  profile={profile}
                  point={userData.point}
                  logout={()=>document.cookie = "Log-Info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"}
                  chatShow={()=>setChatShow(!chatShow)}
                  />
                  <NavbarSide/>
                  <div className="spacer"/>
                  <Chat
                    userData = {userData}
                    chat = {chat}
                    profile = {profile}
                    friends = {friends}
                    sendMessage = {sendMessage}
                    chatShow = {chatShow}
                    readcount = {readcount}
                    read = {num => {
                      setReadcount(readcount-num);
                      addPoint({ chatRead: true });
                    }}
                    chatHide = {() => setChatShow(false)}
                    chatUpdate = {chatUpdate}
                    addtolist={task => {
                      if(window.location.pathname === "/work") setAddTask(task)
                    }}
                  />
                </div>
                <Switch>
                  <Route
                  path="/account"
                  exact
                  render={()=>
                    <Setting
                      profile={{ ...profile, background: userData.house && userData.house.custom ? userData.house.custom.background : null }}
                      cards={userData.items.cards}
                      save={(pro)=>{
                        setProfile(pro);
                        addPoint({ profile: pro });
                      }}
                    />
                  }
                />
                <Route
                  path="/work"
                  exact
                  render={()=>
                    <Work
                      user={userData}
                      claim={prop=>{
                        addPoint(prop);
                      }}
                      friends={friends}
                      sendMessage={sendMessage}
                      point={userData.point}
                      addTask={addTask}
                      update={(prop, content)=>setUserData(prev => ({...prev, [prop]: content}))}
                      empty={()=> setAddTask({})}
                      chatUpdate={chatUpdate}
                    />
                  }
                />
                <Route
                  path="/play"
                  exact
                  render={()=> 
                    <Play 
                      userData={userData}
                      point={userData.point}
                      profile={profile}
                      saveData={(props)=> addPoint(props)}
                    />
                  }
                />
                <Route
                  path="/guide"
                  exact
                  render={() => <Guide/>}
                />
                <Route 
                  path="/home"
                  exact
                  render={() => <DashBoard
                    user={userData}
                    profile={{ ...profile, background: userData.house && userData.house.custom ? userData.house.custom.background : null }}
                    friends={friends}
                    rank={rank}
                    ranking={ranking}
                  />}
                />
                <Route>
                  <Redirect to="/" />
                </Route>
                </Switch>
              </Route>
              </Switch>
            </Router>
          </div>
        :serverdown?
          <ServerDown/>
        :
        <div
          className="text-center"
          style={{maxWidth:"500px",margin:"0 auto",padding:"200px 20px 20px 20px", minHeight:"95vh"}}
        >
          <img alt="icon" src="/icons/motimanager-11.png" width="100%"/>
          <Spinner
            variant="warning"
            animation="grow"
            style={{height:"100px",width:"100px", fontSize:"70px",marginTop:"10px"}}
          />
          {slow?
            <div>
              <h1 className="mt-5">Loading Motimanager...</h1>
              <h5>It's taking loger than expected, please check your connection.</h5>
            </div>
          :null}
        </div>
      }
      </IntlProvider>
      <DemoAlert
        show={demoAlert}
        hide={()=> setDemoAlert(false)}
      />
    </>
  );
}
export default App
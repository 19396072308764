import React from "react"
import { Button, Modal } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default function AlertModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="text-center"
    >
      <div style={{backgroundColor:"#3e2723", color:"white", padding:"20px"}}>
        <h3 className="pb-4" style={{textDecoration:"underline"}}>Sign up to unlock all features!</h3>
        <div style={{textAlign:"left", margin:"auto", maxWidth:"340px"}}>
        <h5><FontAwesomeIcon icon={faCheckCircle} className="mr-2"/> Changes saved to the database</h5>
        <h5><FontAwesomeIcon icon={faCheckCircle} className="mr-2"/> Connect with other users</h5>
        <h5><FontAwesomeIcon icon={faCheckCircle} className="mr-2"/> Be on the ranking</h5>
        <h5><FontAwesomeIcon icon={faCheckCircle} className="mr-2"/> Unlock Crafting</h5>
        </div>
        <h6 style={{color:"rgb(230,230,230)"}} className="mt-3 mb-4">*Everything resets when you reload your page on demo</h6>
        <a href="/register">
          <Button className="mt-2" onClick={()=>{
          document.cookie = "Log-Info=; expires=Thu, 01 Jan 1990 00:00:00 UTC; path=/;"
        }}
         variant="light"
        >Sign Up Now</Button>
        </a>
        <br/>
        <Button className="mt-3" variant="secondary" onClick={()=>{
          document.cookie = "Log-Info=demo; expires=Thu, 01 Jan 2021 00:00:00 UTC; path=/;"
          props.hide()
        }}>Continue on Demo</Button>
      </div>
      {/* <Modal.Header>
       <h3>Sign up to unlock all the features!</h3>
      </Modal.Header>
      <Modal.Body>
        
        <div className="text-left">
        <h4><FontAwesomeIcon icon={faSdCard}/> All the changes will saved to database </h4>
        <h4><FontAwesomeIcon icon={faGlobeAsia}/> Connect with other users and be on the ranking</h4>
        <h4><FontAwesomeIcon icon={faTools}/> Unlock Crafting</h4>
        </div>
        <h5>All you need is your Email!</h5>
        <a href="/register"><Button onClick={()=>{
          document.cookie = "Log-Info=; expires=Thu, 01 Jan 1990 00:00:00 UTC; path=/;"
        }}>Sign Up Now</Button>
        </a>
        <br/>
        <Button onClick={()=>{
          document.cookie = "Log-Info=demo; expires=Thu, 01 Jan 2021 00:00:00 UTC; path=/;"
          props.onHide()
        }}>Continue on Demo</Button>
      </Modal.Body> */}
    </Modal>
  )
}
import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import ChatComponent from "./ChatComponent";
import { Button, FormControl, InputGroup, Modal, Spinner, Image }from "react-bootstrap";
import { injectIntl } from "react-intl";
import api from "../../api"
import ChatBox from "./ChatBox"

import { animated, useSpring } from "react-spring"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUserPlus, faTimes, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

const header = new Headers();
header.append("Set-Cookie", "HttpOnly;Secure;SameSite=Strict");

function MyModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="text-center"
    >
      <Modal.Body>
        <h4>{props.item}</h4>
        <p />
        <Button onClick={props.onHide}>OK</Button>
      </Modal.Body>
    </Modal>
  );
}

const Chat = props => {
  const [modalShow, setModalShow] = useState(false)
  const [sendTo, setSendTo] = useState("")
  const [message, setMessage] = useState("")
  const [search, setSearch] = useState("")
  const [sending, setSending] = useState(false)
  const [addUser, setAddUser] = useState("")
  const [adding, setAdding] = useState(false)
  const [friends, setFriends] = useState([])
  const [chat, setChat] = useState([])
  const [show, setShow] = useState(false)
  const [receiver, setReceiver] = useState({})
  const [, updateState] = React.useState()

  const forceUpdate = useCallback(() => updateState({}), [])

  useEffect(() => { 
    let chats = props.chat.sort(
      (a,b) => (
        a.chat[a.chat.length-1][3] > b.chat[b.chat.length-1][3]
      ) ?
        -1 :
      (
        b.chat[b.chat.length-1][3] > a.chat[a.chat.length-1][3]
      ) ? 1 : 0
    )
    let change = false
    for(let i = 0; i < chats.length; i++){
      if(chats[i].chat.length > 50) {
        let spl = chats[i].chat
        spl.splice(0,chats[i].chat.length - 40)
        change = true
        chats[i].chat = spl
      }
    }
    if(change){
      let data = { username: props.userData.username, chat: chats }
      axios.post(api+"/friends/setfriends", data)
    }
    let friendArr = []
    for(let i  =0; i < chats.length;i++){
      for(let x = 0; x < props.friends.length; x++){
        if(chats[i].username === props.friends[x].username) friendArr.push(props.friends[x])
      }
    }
    setFriends(friendArr)
    for(let i = 0; i < props.chat.length; i++){
      if(props.chat[i].username === sendTo){
        if(props.chat[i].read && props.chatShow){
          reading(sendTo)
          if(props.readcount - props.chat[i].read > 0) document.title = `(${props.readcount - props.chat[i].read}) motimanager`
          else document.title = `motimanager`
          props.read(props.chat[i].read)
          props.chat[i].read = 0
        }
        setChat(props.chat[i].chat)
        forceUpdate()
      }
    }
  },[props.chat])

  useEffect(()=>{
    if(!props.chatShow) setShow(false)
  },[props.chatShow])

  const fade = useSpring({
    opacity: props.chatShow? 1:0.2,
    height: props.chatShow? "100vh": "0vh",
  })

  const chatStyle = useSpring({
    height: show? "400px" : "0px",
    overflow: "hidden"
  })

  const sendmessage = () => {
    if (
      message && !sending
    ) {
      let today = new Date();
      let minute = today.getMinutes()
      if(minute < 10) minute = "0" + 1
      let hour = today.getHours()
      let newMessage = [message, "S", `${hour}:${minute}`, 0]
      let res = ["Hi there, I'd like to assist you but first I suggest you sign up!", "R", `${hour}:${minute}`, 0]
      for(let i = 0; i < props.chat.length; i++){
        if(props.chat[i].username === sendTo) {
          props.chat[i].chat.push(newMessage)
          if( sendTo === "admin" ) setTimeout(()=>{
            props.chat[i].chat.push(res)
            forceUpdate()
          },300)
        }
      }
      setMessage("")
    } else if (
      message && !sending
    ) {
      let last = chat[chat.length-1][3]
      setSending(true)
      const info = {
        owner: props.userData.username,
        content: message,
        username: sendTo,
        last: last
      }
      if (sendTo === "admin") {
        axios.post(api+"/friends/bot", info, header).then(res => {
          setSending(false)
          props.chatUpdate()
        })
      }
      else {
        axios.post(api+"/friends/send", info, header).then(res => {
          setSending(false)
          props.sendMessage(sendTo)
          props.chatUpdate()
        });
      }
      setMessage("")
    }
  }

  const addfriend = () => {
    if (addUser) {
      setAdding(true)
      let already = false;
      for (let i = 0; i < props.friends.length; i++) {
        if (props.friends[i].username === addUser) {
          already = true
        }
      }
      if (!already && addUser !== props.userData.username) {
        axios.post(api+"/users/check", {username: addUser}).then(res => {
          if(res.data !== "none"){
            let info = {
              username: addUser,
              disname: res.data,
              owner: props.userData.username,
              ownername: props.userData.disname
            };
            axios.post(api+"/friends/add", info, header)
            .then(() => {
              axios.post(api+"/users/addfriend", info, header)
              .then(() => {
                let add = props.userData.friends
                add.unshift(addUser)
                props.added(add)
                setAdding(false)
                setAddUser("")
              })
            })
          } else{
            setAdding(false)
          }
        })
      } else {
        setAdding(false)
      }
    }
  }

  const reading = id => {
    const theuser = {
      username: props.userData.username,
      theuser: id
    }
    axios.post(api+"/friends/read", theuser, header)
  }

  return (
    <animated.div style={fade} className="chat-container">
      <animated.div style={fade} className="chat-container2 text-light">
        <div className="spacer-chat"/>
        <div>
          <InputGroup className="p-2">
            <h4 style={{padding:"0px 30px", marginTop:"5px"}}>Messages</h4>
            {adding?
              <Spinner variant="light" animation="border" className="ml-auto"/>
            :
              <Button variant="info" className="ml-auto" style={{padding:"1px 10px"}}>
                {props.userData.username === "demo"?
                <FontAwesomeIcon icon={faLock} onClick={addfriend}/>
                :
                <FontAwesomeIcon icon={faUserPlus} onClick={addfriend}/>
                }
              </Button>
            }
            <div onClick={()=>props.chatHide()} className="pr-3 pl-3 hoverimage">
              <FontAwesomeIcon style={{fontSize:"40px"}} icon={faTimes}/>
            </div>
          </InputGroup>
          <div className="pl-3 pr-3 pb-2">
            <FormControl
              name="search"
              placeholder="Search friend..."
              value={search}
              style={{fontSize:"13px", padding:"0px 10px"}}
              onChange={event=>setSearch(event.target.value)}
            />
          </div>
          <animated.div className = "text-dark bg-light" style={chatStyle}>
            {receiver?
              <div style={{backgroundColor:"#927a77", color: "white", padding:"5px"}}>
                <InputGroup>
                  <Image
                    roundedCircle
                    style={{width:"30px", height:"30px", backgroundColor: receiver.background, marginLeft:"20px"}}
                    src={`/images/cards/${receiver.icon}.png`}
                  />
                  <h5 className="ml-3 pt-1 font-weight-bold">{receiver.disname}</h5>
                  <FontAwesomeIcon className="ml-auto mr-2 hoverimage" size="2x" icon={faTimes} onClick={()=> setShow(false)} />
                </InputGroup>
              </div>
            :null}
            <ChatBox sendTo={sendTo} chat={chat} addtolist={props.addtolist} username={props.userData.username} />
            <InputGroup className="p-2">
              <FormControl placeholder="Type a message..."
                style={{border: "solid 2px rgba(0,0,0,0.3)"}}
                onChange={e=> setMessage(e.target.value)}
                value={message}
                onKeyDown={event=>{
                  if (event.keyCode === 13 && event.shiftKey === false) {
                    sendmessage()
                  }
                }} 
              />
              <div className="p-2 hoverimage" onClick={()=>sendmessage()}>
                <FontAwesomeIcon className="text-orange" style={{fontSize:"25px"}} icon={faPaperPlane}/>
              </div>
            </InputGroup>
          </animated.div>
          <div style={{overflowY: "auto",height:"auto"}}>
            {friends.map((friend, key) => {
              let chatcon = []
              let read = 0
              for(let i = 0; i < props.chat.length; i++){
                if(props.chat[i].username === friend.username) {
                  chatcon = props.chat[i].chat
                  read = props.chat[i].read
                }
              }
              let uppersearch = search.toUpperCase();
              let searchf = friend.disname.toUpperCase().indexOf(uppersearch);
              if ( searchf > -1 || !search ) {
                return (
                  <div
                    key={key}
                    onClick={()=>{
                      if(show){
                        setShow(false)
                      } else {
                        setSendTo(friend.username)
                        setChat(chatcon)
                        setReceiver(friend)
                        setShow(!show)
                        if (read) {
                          props.read(read)
                          for(let i=0; i < props.chat.length; i++){
                            if(props.chat[i].username === friend.username) props.chat[i].read = 0
                          }
                          if(props.readcount - read > 0) document.title = `(${props.readcount - read}) motimanager`
                          else document.title = `motimanager`
                          reading(friend.username)
                        }
                      }
                    }}
                  >
                    <ChatComponent
                      friend={friend}
                      read={read}
                    />
                  </div>
                );
              }
              return "";
            })}
          </div>
        </div>
        <div style={{width:"500px"}}/>
        <MyModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </animated.div>
    </animated.div>
  )
}

export default injectIntl(Chat);
var CryptoJS = require("crypto-js");

function Encrypt(text){
  var ciphertext = CryptoJS.AES.encrypt(text, 'Private Key').toString();
  return ciphertext;
} 

function EncryptObject(data){
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'Private Key').toString();
  return ciphertext;
} 
 
function Decrypt(text){
  var bytes  = CryptoJS.AES.decrypt(text, 'Private Key');
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(originalText)
}

module.exports = { Decrypt, Encrypt, EncryptObject }
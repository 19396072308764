import React from "react"
import { Button, Modal } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/free-solid-svg-icons'

const style = {margin:"10px", fontSize: "20px", fontWeight: "bold"}

export default function AlertModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="text-center"
      style={{ fontFamily: "Montserrat" }}
    >
      {props.dayoff?
        <div>
          <Modal.Title className="mt-3">Are you sure?</Modal.Title>
          <Modal.Body>
              <h5>Once you confirm this, you cannot go back and push work until tomorrow.</h5>
              <p/>
            <Button variant="success" style={style} onClick={props.takeDayoff}>Take a Dayoff</Button>
            <Button variant="info" style={style} onClick={props.onHide}>Never Mind</Button>
          </Modal.Body>
        </div>
      :props.done?
        <Modal.Body>
          <p/>
          <h3>Are you sure?</h3>
          <p/>
          <Button style={style} onClick={props.finish}>Yes!</Button>
          <Button style={style} onClick={props.onHide}>Never Mind</Button>
        </Modal.Body>:
            <Modal.Body>
              <h2>You Got</h2>
            <h1><FontAwesomeIcon icon={faCoins} className="text-yellow"/> {Math.round(props.coin)}</h1>
            <p/>
          <Button style={style} variant="success" onClick={props.onHide}>Great!</Button>
        </Modal.Body>
        }
      </Modal>
    );
  }    

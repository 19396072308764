import React from "react";
import { Image, Button } from "react-bootstrap"
import {injectIntl, FormattedMessage} from "react-intl"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faGem, faMedal, faSignOutAlt, faStar, faBriefcase, faGamepad, faUserCircle, faComment } from '@fortawesome/free-solid-svg-icons'
import { animated, useSpring } from "react-spring"
import { Dashboard, Menu } from '@material-ui/icons';
import { Link } from "react-router-dom";

const style = { width:"40px", textAlign:"left" }

function Navbar(props) {
  const [nav, setNav] = React.useState(false)
  const fade = useSpring({
    opacity: nav? 1:0,
    pointerEvents: nav? "all" : "none",
    left: nav? "0%" : "-35%",
    width: nav? "100vw" : "10vw"
  })

  React.useEffect(()=>{
    if(props.read > 0) document.title = `(${props.read}) motimanager`
    else document.tile = `motimanager`
  },[props.read])

  return (
    <div>
      <animated.div style={fade} className="nav-modal-back" onClick={()=>setNav(!nav)}>
        <div className="nav-modal">
          <div className="d-flex">
            <Menu fontSize="large" className="mt-1 mb-1 hamburg" />
            <Link to="/">
              <Image className="hamburg" style={{width:"50px",height:"auto",marginLeft:"5px",marginTop:"4px"}} src='/icons/motiicon.png'/>
            </Link> 
          </div>
          <div className="ml-3">
            <Link to="/home" className="d-flex mt-4 customLink2">
              <div style={style}>
                <Dashboard style={{fontSize:"26px"}}/>
              </div>
              <div style={{paddingTop:"1px"}}>
                Dashboard
              </div>
            </Link> 
            <Link to="/work" className="d-flex mt-4 customLink2">
              <div style={style}>
                <FontAwesomeIcon size="lg" icon={faBriefcase} style={{fontSize:"22px"}}/>
              </div>
              <div style={{paddingTop:"1px"}}>
                Work
              </div>
            </Link> 
            <Link to="/play" className="d-flex mt-4 customLink2">
              <div style={style}>
                <FontAwesomeIcon size="lg" icon={faGamepad} style={{fontSize:"20px"}}/>
              </div>
              <div style={{paddingTop:"1px"}}>
                Play
              </div>
            </Link> 
            <Link to="/account" className="d-flex mt-4 customLink2">
              <div style={style}>
                <FontAwesomeIcon size="lg" icon={faUserCircle} style={{fontSize:"25px"}}/>
              </div>
              <div>
                <FormattedMessage id="account"/>
              </div>
            </Link> 
          </div>
        </div>
      </animated.div>

      <div className="topbar">
        <div className="d-flex">
          <div className="d-flex">
            <div onClick={()=>setNav(!nav)}>
              <Menu fontSize="large" className="mt-1 mb-1 hamburg"/>
            </div>
            <Link to="/"><Image className="hamburg" style={{width:"50px",height:"auto",marginLeft:"5px",marginTop:"4px"}} src='/icons/motiicon.png'/></Link> 
          </div>
          <div className="ml-auto d-flex">
            <div className="d-flex pt-2">
              <h5 className="text-light" style={{paddingLeft:"10px",maxWidth:"25vw",overflow:"hidden",whiteSpace:"nowrap",maxHeight:"30px"}}>{props.profile.disname}</h5>
              <FontAwesomeIcon
                icon={faMedal}
                size="lg"
                style={{fontSize:"20px",marginLeft:"5px",marginTop:"1px",
                color:props.user.point.rank<20?"rgb(224, 137, 65)":props.user.point.rank<40?"rgb(190, 190, 190)":props.user.point.rank<60?"rgb(255, 199, 16)":"rgb(119, 174, 255)"}}
                className="gold"
              />
            </div>
            <div className="d-none d-sm-block pt-2 pl-3">
              <div className="d-flex">
                <FontAwesomeIcon icon={faStar} size="lg" className="text-orange" style={{fontSize:"20px",paddingTop:"2px",paddingRight:"3px"}}/>
                <h5 className="text-light pr-3">{props.point.rank}</h5>
                <FontAwesomeIcon icon={faCoins} size="lg" style={{color:"rgb(248, 202, 51)",fontSize:"20px",paddingTop:"2px",paddingRight:"3px"}}/>
                <h5 className="text-light pr-3">{props.point.coin}</h5>
                <FontAwesomeIcon icon={faGem} size="lg" className="pt-1" style={{color:"#75E0DD",paddingRight:"3px"}}/>
                <h5 className="text-light">{props.point.gem}</h5>
              </div>
            </div>
            <div onClick={()=>props.chatShow()} className="hoverimage pl-3 pr-2 position-relative" style={{paddingTop:"3px"}}>
              {props.read > 0?
              <div style={{backgroundColor:"orange", position:"absolute", right:"10%", borderRadius:"50%",padding:"0px 5px",fontWeight:"bold", fontSize:"12px"}}>
                {props.read}
              </div>
              :null}
              <FontAwesomeIcon icon={faComment} size="2x" />
            </div>
            <a href="/" onClick={props.logout} className="hoverimage" style={{paddingTop:"1px"}}>
              {props.user.username === "demo"?
              <Button className="notbig mb-2 mt-1" style={{padding:"3px 6px"}}>SIGN UP</Button>
              :
              <FontAwesomeIcon icon={faSignOutAlt} className="hoverimage" size="2x" style={{paddingTop:"4px", color:"white"}}/>
              }
            </a> 
            <div style={{width:"1.5vw"}}/>
          </div>
        </div>
        <div className="d-block d-sm-none">
          <div className="d-flex">
            <FontAwesomeIcon className="ml-auto text-orange" icon={faStar} size="1x" style={{fontSize:"20px",paddingRight:"3px"}}/>
              <h6 className="text-light pr-3">{props.point.rank}</h6>
            <FontAwesomeIcon icon={faCoins} size="1x" style={{color:"rgb(248, 202, 51)",fontSize:"17px",paddingRight:"3px"}}/>
            <h6 className="text-light pr-3">{props.point.coin}</h6>
            <FontAwesomeIcon icon={faGem} size="1x"  style={{color:"#75E0DD",paddingRight:"3px"}}/>
            <h6 className="text-light pr-3">{props.point.gem}</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(
  Navbar
)

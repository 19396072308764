import React, { useState, createRef, useEffect } from "react";
import axios from 'axios';
import api from "../api"
import Intro from "../Guide/Intro"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import { Encrypt } from "../Home/Work/Module/TaskDecrypt"

import { FormControl, Spinner, Image, Button } from "react-bootstrap"

// const jwt = require('jwt-simple');
// const config = require('./config');

// function tokenForUser(user) {
//   const timestamp = new Date().getTime();
//   return jwt.encode({ sub: user.id, iat: timestamp }, config.secret);
// }

const Login =props=>{
  const [ username, setUsername ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ keep, setKeep ] = useState(true)
  const [ loggingIn, setLoggingIn ] = useState(false)
  const [ incomplete, setIncomplete ] = useState("")
  const [ imageLoad, setImageLoad ] = useState(false)

  useEffect(()=>{
    setTimeout(()=>{
      setImageLoad(true)
    },500)
  },[])

  const myRef = createRef()

  const scrollTo=()=>window.scrollTo(0, myRef.current.offsetTop) 

  const logIn =()=> {
    setIncomplete("")
    setLoggingIn(true)
    if( !username || !password ){
      setIncomplete("Form Incomplete")
      setLoggingIn(false)
    } else{
      const check = {
        username: username,
        password: password
      }
      axios.post(api + "/users/login", check)
      .then(res=>{
        if(res.data==="none"){
          setIncomplete("Wrong Email or Password")
          setLoggingIn(false)
        } else {
          const NewToken = Encrypt(JSON.stringify(check))
          if(keep){
            var d = new Date();
            d.setTime(d.getTime() + (30*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = "Log-Info="+NewToken+";"+ expires + ";path=/";
          } else{
            document.cookie = "Log-Info="+NewToken+";path=/";
          }
          props.login(check)
        }
      }).catch(()=>{
        setIncomplete("Please check your connection.")
        setLoggingIn(false)
      })
    }
  }
  return (
    <div>
      <div className="gradientBG p-2" style={{minHeight:"100vh"}}>
        <div style={{height:"15vh"}}/>
        <div className="text-left text-light m-auto resize" style={{maxWidth: "380px"}}>
          <Image className="mb-3" style={{width:"100%"}} alt="icon" src="/icons/motimanager-12.png"/>
          <h6 className="mb-5 text-center font-weight-bold" style={{fontSize:"19px"}}>
            Best set-up for a productive life
          </h6>
          <div style={{ fontSize: "110%" }} className="mb-3">*The online service of Motimanager has ended on the 19th of December 2022. You can still use the app offline. 
          Thank you all for love and support!</div>
          <a href="/home">
            <Button variant="light" className="w-100 mt-3">
              <h3 className="m-0 p-0">OFFLINE MODE</h3>
            </Button>
          </a> 
          <h4 onClick={scrollTo} className="customLink2" style={{ width:"100%",margin: "20px auto", fontSize:"25px"}}>
            Why Motimanager?
          </h4>
          <div className="mt-3" style={{ opacity: 0.5, pointerEvents: "none" }}>
          <div className="position-relative">
            <label style={username===""?{fontSize:"23px",top:"-20%"}:{fontSize:"13px",top:"-27%",paddingLeft:"12px"}}>
              Email
            </label>
            <FormControl
              disabled
              type="email"
              value = {username}
              name="username"
              onChange={event=>setUsername(event.target.value)}
              style={{marginBottom: "20px",paddingTop:username===""?"0":"20px",height:"50px",fontSize:"18px"}}
              onKeyDown={event=>{
                if (event.keyCode === 13 && event.shiftKey === false) {
                  logIn();
                }
              }}
            />
          </div>
          <div className="position-relative">
            <label style={password===""?{fontSize:"23px",top:"-20%"}:{fontSize:"13px",top:"-27%",paddingLeft:"12px"}}>
              Password
            </label>
            <FormControl
              disabled
              type="password"
              value={password}
              name="password"
              onChange={event=>setPassword(event.target.value)}
              style={{ marginBottom: "20px", paddingTop: password===""?"0":"20px",height:"50px",fontSize:"18px" }}
              onKeyDown={event=>{
                if (event.keyCode === 13 && event.shiftKey === false) {
                  logIn();
                }
              }}
            />
          </div>
          {loggingIn ? (
            <h5 className="text-center font-weight-bold">
              <Spinner variant="light" animation="border" style={{height:"40px",width:"40px",fontSize:"25px"}}/>
            </h5>
            ) : (
            <div>
              <div style={{marginLeft:"30%"}}>
                <div className="position-relative" style={{height:"30px"}}>
                  <FontAwesomeIcon
                    icon={keep?faCheckCircle:faCircle}
                    className="checkmark"
                    onClick={()=>setKeep(!keep)}
                  />
                  <div style={{cursor:"pointer",position:"absolute",left:"10%",top:"-5%"}} onClick={()=>setKeep(!keep)}>
                    Remember Me
                  </div>
                </div>
              </div>
              <button className="btn-green" onClick={logIn}>
                Log In
              </button>
              {incomplete?
                <h4 style={{color:"white",paddingTop:"10px"}} className="text-center">
                  {incomplete}
                </h4>
              :null}
              <p className="bolder">OR</p>
              <Link to="/home" className="customLink">Sign Up</Link> 
            </div>
            )}
            </div>
        </div>
      </div>
      <div ref={myRef}>
        <Intro imageLoad={imageLoad} />
      </div>
    </div>
  );
}

export default Login;

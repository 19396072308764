export default function Card(name){
    let hpadd = Math.floor(Math.random()*200)
    let apadd =Math.floor(Math.random()*80)
    let ap2add =Math.floor(Math.random()*80)

    let next
    switch(name){
        case "Boi":
          next={name: "Boi", hp: 1000+hpadd, ap: 200+apadd,ap2:400+ap2add,cost:1,buff:0,equip:0}
          break;
        case "Gurl":
          next={name: "Gurl", hp: 900+hpadd, ap: 10+apadd,ap2:1300+ap2add,cost:3,buff:0,equip:0}
          break;
        case "Zombie":
          next={name: "Zombie", hp: 1100+hpadd, ap: 300+apadd,ap2:600+ap2add,cost:2,buff:0,equip:0}
          break;
        case "Aqua":
          next={name: "Aqua", hp: 1200+hpadd, ap: 200+apadd,ap2:700+ap2add,cost:2,buff:0,equip:0}
          break;
        case "Witch":
          next={name: "Witch", hp: 1400+hpadd, ap: 450+apadd,ap2:700+ap2add,cost:3,buff:0,equip:0}
          break;
        case "Ghost":
          next={name: "Ghost", hp: 1600+hpadd, ap: 500+apadd,ap2:900+ap2add,cost:4,buff:0,equip:0}
          break;
        case "Golem":
          next={name: "Golem", hp: 2000+hpadd, ap: 400+apadd,ap2:500+ap2add,cost:3,buff:0,equip:0}
          break;
        case "Therapist":
          next={name: "Therapist", hp: 1700+hpadd, ap: 550+apadd,ap2:750+ap2add,cost:4,buff:0,equip:0}
          break;
        case "Elekt":
          next={name: "Elekt", hp: 1650+hpadd, ap: 600+apadd,ap2:800+ap2add,cost:4,buff:0,equip:0}
          break;
        case "Mage":
          next={name: "Mage", hp: 1750+hpadd, ap: 700+apadd,ap2:950+ap2add,cost:5,buff:0,equip:0}
          break;
        case "Necro":
          next={name: "Necro", hp: 1800+hpadd, ap: 500+apadd,ap2:1200+ap2add,cost:5,buff:0,equip:0}
          break;
        case "Dragon":
          next={name: "Dragon", hp: 1950+hpadd, ap: 900+apadd,ap2:1000+ap2add,cost:6,buff:0,equip:0}
          break;
        case "Wizzard":
          next={name: "Wizzard", hp: 1850+hpadd, ap: 600+apadd,ap2:1300+ap2add,cost:6,buff:0,equip:0}
          break;
        default:
          break
      }
    return next
}
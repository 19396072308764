import React, { useEffect, useState } from "react"
import { Button, FormControl, Modal } from "react-bootstrap"
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core"

const style = {margin:"10px", fontSize: "20px", fontWeight: "bold"}

export default function EditQuest({ open, onHide, save, quests }) {
  const [edit, setEdit] = useState({ daily: [], weekly: [] });

  useEffect(()=>{
    setEdit(quests);
  },[open])

  return (
    <Modal
      show={open}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="text-center"
      style={{ fontFamily: "Montserrat" }}
      onHide={onHide}
    >
      <Modal.Title className="mt-3" style={{ fontWeight: "bold" }}>Customize Your Quest</Modal.Title>
        <Modal.Body>
          <Table>
            <TableBody>
              {edit.daily.map((quest,key)=>(
                <TableRow>
                  <TableCell style={{ fontFamily: "Montserrat", padding: "5px 10px" }}>Daily Quest {key + 1}</TableCell>
                  <TableCell style={{ fontFamily: "Montserrat", padding: "5px 10px" }}><FormControl value={quest.description} 
                  onChange={event=>{
                    const que = [...quests.daily];
                    que[key].description = event.target.value;
                    setEdit({ ...edit, daily: que });
                  }}/></TableCell>
                </TableRow>
              ))}
              {edit.weekly.map((quest,key)=>(
                <TableRow>
                  <TableCell style={{ fontFamily: "Montserrat", padding: "5px 10px" }}>Weekly Quest {key + 1}</TableCell>
                  <TableCell style={{ fontFamily: "Montserrat", padding: "5px 10px" }}><FormControl value={quest.description} 
                  onChange={event=>{
                    const que = [...quests.weekly];
                    que[key].description = event.target.value;
                    setEdit({ ...edit, weekly: que });
                  }}/></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button style={style} onClick={()=>save(edit)}>Save</Button>
          <Button style={style} variant="danger" onClick={onHide}>Cancel</Button>
        </Modal.Body>
      </Modal>
    );
  }    

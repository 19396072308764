import React from "react"
import { Image, Row, Col } from "react-bootstrap"


const ChatComponent=props=> {
  return (
    <div
      key={props.friend.username}
      style={{
        height: "auto",
        paddingTop: "15px"
      }}
      className="chat-block text-center"
    >
        <Row className="m-0">
          <Col md={2} sm={2} xs={2} >
              <div className="m-auto">
            <div className="position-relative">
            {props.friend.online?
            <div style={{borderRadius:"15px",height:"15px",width:"15px", backgroundColor:"rgb(100,255,100)", position:"absolute", left:"15%"}} />
            :null}
            <Image roundedCircle style={{width:"50px",height:"50px" ,backgroundColor: props.friend.background? props.friend.background : "white" }} alt="icon" src={`/images/cards/${props.friend.icon}.png`}/>
            </div>
            </div>
          </Col>
          <Col>
            <h5 id={props.friend.username} style={{ color: "white" }}>
              {props.friend.disname}
            </h5>
            <p style={{ color: "rgb(150,150,150)",fontSize:"15px"}}>{props.friend.message}</p>
          </Col>
          <Col className="p-0" md={2} sm={2} xs={2}>
            {props.read?
            <div
              style={{borderRadius: "15px", fontWeight: "bold",backgroundColor: "orange", color: "white",fontSize: "25px", marginTop:"7px", maxWidth:"40px", padding:"0 10px"}}
            >
              {props.read < 10? props.read : "9+"}
            </div>
            :null}
          </Col>
        </Row>
    </div>
  );
}

export default ChatComponent

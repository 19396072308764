import React from "react"
import { Modal, Button, Image } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faHandRock } from '@fortawesome/free-solid-svg-icons'
import rarityReturn from "../Craft/rarityReturn"
import CardUI from "../Battle/CardUI";

export default function MyModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
      >
        <div className="play-bg" style={{ color:"white", padding:"20px", fontFamily:"Montserrat" }}>
          {props.success?<h1>Success</h1>:<h1>Fail!</h1>}
          <h3>You Got</h3>
          <div className="card-game m-auto border-orange">
            {props.item.hp ? (
              <CardUI card={props.item} />
            ) : (
              <>
              <Image
              rounded
              className={props.item.hp?rarityReturn(props.item.name):props.item.fname}
              style={{width:"100%"}}
              src={`/images/cards/${props.item.name}.png`}
              />
              <h5 className="mt-2">{props.item.hp? props.item.name : props.item.fname+" "+props.item.name}</h5>
              </>
            )}
          </div>
          <p/>
          <Button variant="info" onClick={props.onHide}>OK</Button>
        </div>
      </Modal>
    );
  }
export default{
    cancel: "Cancel",
    //Header
    quest: "Quest",
    shop: "Shop",
    account: "Account",
    logout: "Logout",
    chat: "Chat",
    closet: "Closet",
    inventory: "Inventory",
    battle: "Battle",
    work2: "Work",
    login: "Sign In",
    register: "Sign Up",
    //Setting
    email: "Email",
    password: "Password",
    disname: "Display Name",
    payinfo: "Payment Method",
    nopayinfo: "No Payment Method",
    language: "Language",
    region:"Region",
    accoinfo: "Your Account",
    profinfo: "Your Profile",
    save: "Save",
    share: "Share",
    //Task
    tasks: "Tasks",
    edit: "Edit",
    delete: "Delete",
    work: "Work",
    title: "Title",
    description: "Description",
    priority: "Priority",
    create: "Create",
    High: "High",
    Medium: "Medium",
    Low: "Low",
    //Work
    task: "Task",
    youareworking: "You're Working!",
    enjoybreak: "Enjoy Your Break!",
    goodjob: "Great Job, Review Now!",
    rest: "Take a Break",
    rework: "Get Back to Work",
    finish: "Finish!",
    review: "Review!",
    confirm: "Confirm",
    readytowork: "Ready to Work?",
    dayoff: "Take a Day Off",
    seeyou: "See You Tomorrow!",
    messagefromlastday: "Message from You on the Last Day!",
    scroll: "Swipe to see more",
    productivehistory: "Productivity History",
    totalworktime: "Total Work Time",
    resttime: "Break Time",
    totalresttime: "Total Break Time",
    //Top
    profile: "Profile",
    friends: "Friends",
    leaderboard: "LeaderBoard",
    //Quest
    dailyquest: "Daily Quest",
    weeklyquest: "Weekly Quest",
    loginquest: "Login to Motimanager",
    workquest: "Get to work",
    hoursquest: "Work for more than 3 hours",
    hours2quest: "Work for more than 6 hours",
    reviewquest: "Review today's work",
    restquest: "Take a day off",
    rest2quest: "Take another day off",
    favquest: "Do your favorite thing",
    //Battle
    choosecard: "Choose a card",
    challenger: "Challenger",
    boss: "Boss"
    
}
export default function gemReturn(arr){
  let gem = 0
  for(let i=0;i<arr.length;i++){
    let item = arr[i].name
    if(arr[i].fname){item=arr[i].fname}
    if(item==="Rare"||item==="Gurl"||item==="Aqua"||item==="Witch"||item==="Book of"){gem+=5}
    else if(item==="Epic"||item==="Ghost"||item==="Therapist"||item==="Elekt"){gem+=10}
    else if(item==="Mystic"||item==="Mage"||item==="Golem"||item==="Necro"){gem+=20}
    else if(item==="Legendary"||item==="Wizzard"||item==="Dragon"){gem+=50}
    else gem+=2
  }
  return(gem)
}
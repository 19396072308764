import React from "react";
import FSource from "./Fsource";
import ScrollToBottom from "react-scroll-to-bottom";

const ChatBox = props => {
  const ROOT_CSS = {
    height: 290,
    fontWeight: "bold",
    textAlign: "left"
  }

  return (
    <ScrollToBottom>
      <div style={ROOT_CSS}>
      {props.chat.map((chat, key) => {
        let style = {
          backgroundColor: "lightgreen",
          marginRight: "3%",
          marginLeft: "25%",
          marginTop: "6px",
          marginBottom: "6px",
          textAlign: "left",
          padding: "5px 10px",
          borderRadius: "10px"
        };
        let timeline = false;
        if (chat[1] === "R") {
          style.marginRight = "25%";
          style.marginLeft = "3%";
          style.backgroundColor = "pink";
        }
        if (chat[1] === "C") {
          timeline = true;
          style = {
            backgroundColor: "rgb(240,240,240)",
            marginRight: "30%",
            marginLeft: "30%",
            marginTop: "15px",
            marginBottom: "15px",
            textAlign: "center",
            padding: "5px 10px",
            color: "rgb(85,85,85)",
            borderRadius: "10px"
          };
        }
        return (
          <FSource key={key} chat={chat} style={style} timeline={timeline} addtolist={props.addtolist} username={props.username} />
        );
      })}
      </div>
    </ScrollToBottom>
  );
};

export default ChatBox;

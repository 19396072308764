import React, { useState, useEffect } from "react"
import Battle from "./Battle/Battle"
import Shop from "./Shop/Shop"
import Craft from "./Craft/Craft"
import House from "./House/House"
import { Row, Col } from "react-bootstrap"

const pages = ["Battle","Shop","Craft","House"]

const active = { borderBottom: "6px solid black", paddingBottom:"0", color:"black" }

const Play = ({ userData, profile, point, saveData }) => {
    const [page, setPage] = useState(pages[0])
    const [cards, setCards] = useState([])
    const [buffs, setBuffs] = useState([])
    const [game, setGame] = useState(false)
    const [ready, setReady] = useState(false)
    const [box, setBox] = useState({ furnitures: ["Clock"], backgrounds: ["rgb(255,255,255)"] });
    const [custom, setCustom] = useState({ background: "rgb(255,255,255)", furnitures: ["Clock"], icon: profile.icon });

    useEffect(()=>{
        setReady(false)
        setCards(userData.items.cards)
        setBuffs(userData.items.buffs)
        setReady(true)
        if(userData.house) {
            setBox(userData.house.box);
            setCustom({ ...userData.house.custom, icon: profile.icon });
        } 
    },[userData.items.buffs, userData.items.cards, userData.username])

    return(
        <div className="inside-container text-center play-bg" style={{minHeight:"100vh"}}>
            {!game?
            <Row className="m-0">
                {pages.map((pa, key)=>
                (
                    <Col className="m-0 p-0" key={key}>
                    <div className="tab" style={page === pa? active : null}
                    onClick={()=>{
                        if(ready) setPage(pa)
                    }}>
                    <h3 className="auto-size font-weight-bold">{pa}</h3>
                    </div>
                </Col>
                ))}
            </Row>
            :null}
            <div className="pt-2">
                {page === "Battle"?
                <Battle userData={userData} 
                profile={{ ...profile, background: custom.background }} 
                point={point} 
                cardCollection={cards}
                buffs={buffs}
                saveData={saveData}
                startGame={g=> setGame(g)}
                ready={ready}
                />
                : page === "House" ?
                <House profile={profile}
                custom={custom} 
                setCustom={(cus)=>{
                    setCustom(cus);
                    saveData({ house: { box, custom: cus } })
                }}
                box={box} />
                : page==="Shop"?
                <Shop 
                user={userData}
                point={point}
                saveData={saveData}
                cards={cards}
                buffs={buffs}
                setCards={arr=>setCards(arr)}
                setBuffs={arr=>setBuffs(arr)}
                box={box}
                custom={custom}
                setBox={(props) => {
                    setBox(props);
                }}
                />
                : page==="Craft"?
                <Craft
                user={userData}
                point={point}
                saveData={saveData}
                cards={cards}
                buffs={buffs}
                setCards={arr=>setCards(arr)}
                setBuffs={arr=>setBuffs(arr)}
                />
                :null}
            </div>
        </div>
    )
}

export default Play
import React from "react"

export default function Guide(){
    return(
        <div className="text-left p-2" style={{maxWidth:"700px", margin:"50px auto"}}>
            <h4>1. Get coins and gems by being productive everyday!</h4>
            <br/>
            <br/>
            <h4>2. Spend them to build a strong deck!</h4>
            <br/>
            <br/>
            <h4>3. Beat challengers and climb up the ranking!</h4>
            <br/>
            <br/>
            <h4>4. Outshine your friends!</h4>
            <br/>
            <br/>
            <h5>If you have questions or want to report bugs,<br/> contact me here!</h5>
            <br/>
            <h3>Nao Mori: lockn980@gmail.com</h3>
        </div>
    )
}
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
//import { FormattedMessage } from "react-intl";
import rarityReturn from "./rarityReturn";
import chanceReturn from "./chanceReturn"

import PickCard from "./PickCard"
import ResultModal from "./ResultModal"
import { Row, Col, Image, Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem } from '@fortawesome/free-solid-svg-icons'
import api from "../../../api"
import CardUI from "../Battle/CardUI";

var combineSaved
var cardsSaved = []
var buffsSaved = []

const Craft = props => {
  const [cards, setCards] = useState(props.cards)
  const [buffs, setBuffs] = useState(props.buffs)
  const [combineItems, setCombineItems] = useState([undefined, undefined, undefined])

  const [modalShow, setModalShow] = useState(false)
  const [resultModalShow, setResultModalShow] = useState(false)

  const [editNum, setEditNum] = useState(false)
  const [combined, setCombined] = useState({})
  const [both, setBoth] = useState(false)
  const [selected, setSelected] = useState({ cards: {}, buffs: {} });

  const [chance, setChance] = useState(0)
  const [cost, setCost] = useState(0)
  const [success, setSuccess] = useState(false)
  const [, updateState] = React.useState();

  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(()=>{
    combineSaved = [undefined, undefined, undefined]
    cardsSaved = props.cards
    buffsSaved = props.buffs
  },[])

  return (
    <div className="p-2">
      <h3 className="pt-3 pb-5">Choose Cards / Items to Combine!</h3>
      <Row style={{maxWidth:"1000px", margin:"0 auto"}}>
        {combineItems.map((item, key) => {
          return (
            <Col key={key} style={{ paddingTop: 15 }}>
              <div className="card-game m-auto hoverimage">
                {!item?
                  <div
                  style={{minHeight:"260px", paddingTop:"100px"}}
                  onClick={()=>{
                    setModalShow(true)
                    setEditNum(key)
                    if(key) setBoth(true)
                  }}>
                  <h5>{key? "Click to choose an item" : "Click to choose a card or item"}</h5>
                  </div>
                :
                  <div
                  onClick={()=>{
                    if(combineSaved[key].buff) setSelected({ ...selected, buffs: { ...selected.buffs, [combineSaved[key].key]: false } })
                    else setSelected({ ...selected, cards: { ...selected.cards, [combineSaved[key].key]: false } })
                    combineSaved[key] = undefined
                    if(combineSaved[0] && combineSaved[1]){
                      let result = chanceReturn(combineSaved)
                      setChance(result.chance)
                      setCost(result.cost)
                      setCombined(result.next)
                    }
                    setCombineItems(combineSaved)
                    setCards(cardsSaved)
                    setBuffs(buffsSaved)
                    forceUpdate()
                  }}>
                    {item.item.hp?
                      <div>
                        <CardUI card={item.item} cancellable={true} />
                      </div>
                    :
                      <div>
                        <CardUI card={item.item} buff={true} cancellable={true} />
                      </div>
                    }
                  </div>
                }
              </div>
            </Col>
          )
        })}
      </Row>
      {combineItems[0] && combineItems[1] ? 
          <div className="mt-3">
            <h4>Success Rate: {chance}% Cost: <FontAwesomeIcon className="text-blue" icon={faGem}/> {cost}</h4>
            {props.point.gem >= cost?
            <Button
            onClick={()=>{
              let num = Math.floor(Math.random()*100)
              if(chance > num) setSuccess(true)
              else setSuccess(false)
              if(combineSaved[0].buff){
                if(chance > num) buffsSaved[combineSaved[0].key] = combined
                else setCombined(buffsSaved[combineSaved[0].key])
              } else {
                if(chance > num) cardsSaved[combineSaved[0].key] = combined
                else setCombined(cardsSaved[combineSaved[0].key])
              }
              for(let i=1; i<3; i++){
                if(combineSaved[i]){
                  if(combineSaved[i].buff){
                    buffsSaved.splice(combineSaved[i].key, 1)
                  } else cardsSaved.splice(combineSaved[i].key, 1)
                }
              }
              //axios.post(api+"/users/itemdelete", data)
              props.saveData({ point: {coin: props.point.coin, gem: props.point.gem - cost, rank: props.point.rank}, items: { cards: cardsSaved, buffs: buffsSaved } })
              setCards(cardsSaved)
              setBuffs(buffsSaved)
              props.setBuffs(buffsSaved)
              props.setCards(cardsSaved)
              setResultModalShow(true)
              setCombineItems([undefined, undefined, undefined])
              combineSaved = [undefined, undefined, undefined]
            }}
            >
              COMBINE
            </Button>:
            <h4>Not Enough Gems!</h4>
            }
          </div>
        :null}
      <ResultModal
        show={resultModalShow}
        onHide={() => setResultModalShow(false)}
        item={combined}
        success={success}
      />
      <PickCard
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          setEditNum(0)
          setBoth(false)
        }}
        both = {both}
        withBuff = {true}
        buffs = {buffs}
        editNum = {editNum}
        cards={cards}
        edit = {(key, editKey, buff)=>{
          combineSaved[editKey] = {item: buff? buffs[key] : cards[key], key: key, buff: buff}
          setCombineItems(combineSaved)
          setSelected({ ...selected, [buff ? "buffs" : "cards"]: { ...selected[buff ? "buffs" : "cards"], [key]: true } })
          if(combineSaved[0] && combineSaved[1]){
            let result = chanceReturn(combineSaved)
            setChance(result.chance)
            setCost(result.cost)
            setCombined(result.next)
          }
        }}
        selected={selected}
      />
    </div>
  )
}

export default Craft;
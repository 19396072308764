import React, { useState } from "react"
import { Button, Modal, FormControl } from "react-bootstrap"
import { FormattedMessage } from "react-intl"

const WorkModal=({ workhours, reviewcomplete, show })=>{
    const [grade, setGrade ] = useState("")
    const [comment, setComment ] = useState("")

    return(
        <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
        style={{ fontFamily: "Montserrat" }}
        >
            {workhours ? (
                <div className="p-3">
                <h1>Review</h1>
                <p/>
                <h5>Total Work Time: {workhours.work.hour} hours and {workhours.work.minute} minutes</h5>
                <h5>Total Break Time: {workhours.rest ? workhours.rest.hour : 0} hours and {workhours.rest ? workhours.rest.minute : 0} minutes</h5>
                <p/>
                <h4>1. How good did you do?</h4>
                <Button variant={grade==="great"?"success":"secondary"} onClick={()=>setGrade("great")} className="m-1">Great!</Button>
                <Button variant={grade==="good"?"success":"secondary"} onClick={()=>setGrade("good")} className="m-1">Good</Button>
                <Button variant={grade==="ok"?"success":"secondary"} onClick={()=>setGrade("ok")} className="m-1">OK</Button>
                <Button variant={grade==="notgood"?"success":"secondary"} onClick={()=>setGrade("notgood")} className="m-1">Not Good</Button>
                <Button variant={grade==="bad"?"success":"secondary"} onClick={()=>setGrade("bad")} className="m-1">Bad...</Button>
                <p/>
                <h4>2. Message for yourself of the next day! (Optional)</h4>
                <p/>
                <FormControl onChange={event=>setComment(event.target.value)} name="comment" value={comment}/>
                <p/>
                <Button disabled={!Boolean(grade)} onClick={()=>reviewcomplete({grade: grade, comment: comment})}>
                    <FormattedMessage id="confirm"/>
                </Button>
                </div>
            ) : null}
        </Modal>
    )
}

export default WorkModal
import cardReturn from "./cardReturn"

export default function mobCard(rank){
    let ran = Math.floor(Math.random()*3)
    let aicard=[
        cardReturn("Gurl"),
        cardReturn("Boi"),
        undefined
    ]
    if(rank<5){
        if(ran===0){
            aicard=[
                cardReturn("Zombie"),cardReturn("Boi"),undefined
            ]
        } else{
            aicard=[
                cardReturn("Aqua"),cardReturn("Boi"),undefined
            ]
        }
    } else if(rank<10){
        if(ran===0){
            aicard=[
                cardReturn("Zombie"),
                cardReturn("Boi"),
                cardReturn("Boi")
            ]
        } else if(ran===1){
            aicard=[
                cardReturn("Gurl"),
                cardReturn("Boi"),
                cardReturn("Boi")
            ]
        } else {
            aicard=[
                cardReturn("Aqua"),
                cardReturn("Boi"),
                cardReturn("Boi")
            ]
        }
    } else if(rank<15){
        ran = Math.floor(Math.random()*4)
        if(ran===0){
            aicard = [
                cardReturn("Gurl"),
                cardReturn("Zombie"),
                cardReturn("Boi")
            ]
        } else if(ran===1){
            aicard=[
                cardReturn("Witch"),
                cardReturn("Zombie"),
                cardReturn("Boi")
            ]
        } else if(ran===2){
            aicard=[
                cardReturn("Aqua"),
                cardReturn("Zombie"),
                cardReturn("Boi")
            ]
        } else{
            aicard=[
                cardReturn("Aqua"),
                cardReturn("Gurl"),
                cardReturn("Boi")
            ]
        }
    } else if (rank<20){
        ran = Math.floor(Math.random()*4)
        if(ran===0){
            aicard=[
                cardReturn("Ghost"),
                cardReturn("Gurl"),
                cardReturn("Boi")
            ]
        } else if(ran===1){
            aicard=[
                cardReturn("Ghost"),
                cardReturn("Aqua"),
                cardReturn("Zombie")
            ]
        } else if(ran===2){
            aicard=[
                cardReturn("Therapist"),
                cardReturn("Witch"),
                cardReturn("Zombie")
            ]
        } else {
            aicard=[
                cardReturn("Elekt"),
                cardReturn("Aqua"),
                cardReturn("Boi")
            ]
        }
    } else if(rank<30){
        ran = Math.floor(Math.random()*5)
        if(ran===0){
            aicard=[
                cardReturn("Mage"),
                cardReturn("Zombie"),
                cardReturn("Ghost")
            ]
        } else if(ran===1){
            aicard=[
                cardReturn("Therapist"),
                cardReturn("Gurl"),
                cardReturn("Golem")
            ]
        } else if(ran===2){
            aicard=[
                cardReturn("Mage"),
                cardReturn("Witch"),
                cardReturn("Zombie")
            ]
        } else if(ran===3){
            aicard=[
                cardReturn("Necro"),
                cardReturn("Therapist"),
                cardReturn("Gurl")
            ]
        } else{
            aicard=[
                cardReturn("Elekt"),
                cardReturn("Golem"),
                cardReturn("Boi")
            ]
        }
    } else{
        ran = Math.floor(Math.random()*9)
        if(ran===0){
            aicard=[
                cardReturn("Mage"),
                cardReturn("Golem"),
                cardReturn("Zombie")
            ]
        } else if(ran===1){
            aicard=[
                cardReturn("Aqua"),
                cardReturn("Necro"),
                cardReturn("Therapist")
            ]
        } else if(ran===2){
            aicard=[
                cardReturn("Therapist"),
                cardReturn("Golem"),
                cardReturn("Zombie")
            ]
        } else if(ran===3){
            aicard=[
                cardReturn("Necro"),
                cardReturn("Ghost"),
                cardReturn("Gurl")
            ]
        } else if(ran===4){
            aicard=[
                cardReturn("Dragon"),
                cardReturn("Therapist"),
                cardReturn("Gurl")
            ]
        } else if(ran===5){
            aicard=[
                cardReturn("Wizzard"),
                cardReturn("Mage"),
                cardReturn("Boi")
            ]
        } else if(ran===6) {
            aicard=[
                cardReturn("Aqua"),
                cardReturn("Dragon"),
                cardReturn("Elekt")
            ]
        } else if(ran===7) {
            aicard=[
                cardReturn("Witch"),
                cardReturn("Mage"),
                cardReturn("Elekt")
            ]
        } else if(ran===8) {
            aicard=[
                cardReturn("Dragon"),
                cardReturn("Witch"),
                cardReturn("Golem")
            ]
        } else {
            aicard=[
                cardReturn("Wizzard"),
                cardReturn("Ghost"),
                cardReturn("Zombie")
            ]
        }
    }
    if(rank>5){
        let add = rank-5
        let addHp = Math.floor(Math.random() * 5) + 3; //Number between 3-7
        let addAp = 10 - addHp; //Number between 3-7 (The sum of HP and AP is always 12)
        addHp = addHp * 2.6; //Multiply HP as it is less valuable than AP
        for(let i=0;i<3;i++){
            if(aicard[i]!==undefined){
                aicard[i].hp+=Math.round(add*addHp)
                aicard[i].ap+=Math.round(add*addAp)
                aicard[i].ap2+=Math.round(add*addAp)
            }
        }
    }
    return aicard
}
import React, {useState, useEffect} from "react"
import axios from "axios"
import {injectIntl, FormattedMessage} from "react-intl"
import { Button, Modal, Form, Row, Col, FormControl, Image } from "react-bootstrap"
import api from "../api"
import header from "../header"

function IconModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
      >
        <Modal.Body>
          <div>
            <h4>Choose Your Icon</h4>   
            <p/>
            {props.icons.map((card,key)=>{
                return <img className="hoverimage"
                key={key} alt={card}
                style={{width:"150px", height:"150px", margin:"5px"}}
                src={`/images/cards/${card}.png`}
                onClick={()=>{
                    props.edit(card)
                    props.onHide()
                }}
                />
            })}
          </div>
          <p/>
          <Button variant="danger" onClick={props.onHide}>Cancel</Button>
        </Modal.Body>
      </Modal>
    );
}
function SavedModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
        style={{ fontFamily: "Montserrat" }}
      >
        <Modal.Body>
          <h4>Your data has been updated!</h4>
          <p/>
          <Button onClick={props.onHide}>OK</Button>
        </Modal.Body>
      </Modal>
    );
}

const Setting = ({ profile, cards, save }) =>{
    const [modalShow, setModalShow] = useState(false)
    const [displayName, setDisplayName] = useState(profile.disname)
    const [language, setLanguage] = useState("English")
    const [message, setMessage] = useState(profile.message)
    const [region, setRegion] = useState("")
    const [icon, setIcon] = useState(profile.icon)
    const [icons, setIcons] = useState([])
    const [saved, setSaved] = useState(false)

    useEffect(()=>{
        if(cards) {
            let cardArr=["Boi"]
            for(let i=0;i<cards.length;i++){
                let done
                for(let z=0;z<cardArr.length;z++){
                    if(cardArr[z]===cards[i].name){
                        done = true
                        break
                    }
                }
                if(!done) cardArr.push(cards[i].name)
            }
            setIcons(cardArr)
        }
    },[cards])

    const profileEdit=()=>{
        if(displayName) {
            save({ icon, disname: displayName, message })
            setSaved(true)
        }
    }

    const accountEdit=()=>{
        setSaved(true)
    }

    return(
        <div style={{maxWidth:"1000px"}}>
            <div style={{maxWidth:"500px",margin:"0 auto"}} className="inside-container p-3">
                <h4 className="mt-5 mb-3 font-weight-bold"><FormattedMessage id="profinfo" /></h4>
                <Row className="m-0">
                    <Col className="p-0 position-relative">
                        <Image rounded className="hoverimage" width="200px" alt="icon" src={`/images/cards/${icon}.png`} onClick={()=>setModalShow(true)}
                        style={{
                            backgroundColor: profile.background || "white"
                          }}
                        />
                    </Col>
                    <Col className="p-0 pl-2" style={{minWidth:"100px"}}>
                        <h6 className="pt-2">Name</h6>
                        <FormControl value={displayName} onChange={event=>setDisplayName(event.target.value)}/>
                        <h6 className="mt-2">Message</h6>
                        <FormControl as="textarea" rows="2"  value={message} onChange={event=>setMessage(event.target.value)}/>
                    </Col>
                </Row>
                <button className="mt-2 btn bgc-blue" onClick={()=>profileEdit()}><FormattedMessage id="save"/></button>

                <h4 className="mt-5 mb-3 font-weight-bold"><FormattedMessage id="accoinfo" /></h4>
                <p>
                <FormattedMessage id="email" />:
                <br/>
                example@motimanager.com
                </p>
                <p>
                <FormattedMessage id="password" />:
                <br/>
                *****
                </p>
                <FormattedMessage id="language" />:
                <Form.Control className="mb-3"  onChange={event=>setLanguage(event.target.value)}
                    as="select"
                    value={language}
                >
                    <option value="en">English</option>
                    <option value="ja">日本語</option>
                    <option value="de">Deutsch</option>
                </Form.Control>
                {/* <FormattedMessage id="region" />:
                <Form.Control  onChange={event=>setRegion(event.target.value)}
                    as="select"
                    value={region}
                >
                    <option value="ja">日本</option>
                    <option value="de">Deutschland</option>
                </Form.Control> */}
                <button className="mt-2 btn bgc-blue" onClick={()=>accountEdit()}><FormattedMessage id="save"/></button>

                <IconModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    icons={icons}
                    edit={icon_new=>{
                        setIcon(icon_new)
                    }}
                />
                <SavedModal
                    show={saved}
                    onHide={() => setSaved(false)}
                />
            </div>
        </div>
    )

}

export default injectIntl(Setting)
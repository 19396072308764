import React from "react";
import Form from "./RegisterForm";
import axios from "axios";
import api from "../api"

const green = "206, 255, 217"
const red = "255, 233, 241"
const yellow = "254, 255, 206"

class Body1 extends React.Component {
  state = {
    username: "",
    password: "",
    repassword: "",
    year: 0,
    month: 0,
    date:0,
    disname: "",
    payinfo: "",
    incomplete: "",
    monthinput30: false,
    monthinput: false,
    monthinput28: false,
    done: false,
    mailcolor: "255, 255, 255",
    pswcolor: "255, 255, 255",
    repswcolor: "255, 255, 255",
    paycolor: "255,255,255",
    making:false,
  };


  register = data => {
    axios
      .post(api+"/users/add", data)
      .then(res => {
        if (res.data.length > 0) {
          
          const friend = {
            username: this.state.username,
            disname: this.state.disname,
          }
           axios.post(api+"/friends/create", friend).then(res => {
            this.setState({ done: true });
          })
        }
      })
      .catch(err => this.setState({ incomplete: err }));
  };

  confirm = agreement => {
    this.setState({ incomplete: "" });
    if (
      this.state.username === "" ||
      this.state.password === "" ||
      this.state.disname === "" ||
      this.state.date === "" ||
      this.state.year === "" ||
      this.state.month === ""
    ) {
      this.setState({ incomplete: "Please complete the form" });
    } else if (this.state.mailcolor !== green) {
      this.setState({ incomplete: "Email is invalid" });
    } else if (this.state.pswcolor !== green) {
      this.setState({ incomplete: "Password is too weak" });
    } else if (this.state.password !== this.state.repassword)
      this.setState({ incomplete: "Re-entered Password does not match" });
    else {
      if(!agreement){
        this.setState({ incomplete: "No Agreement" });
      } else{
        this.setState({making:true})
      const search ={username: this.state.username}
      axios.post(api+"/users/check", search).then(res => {
      if(res.data!=="none"){
        this.setState({ incomplete: "Email is already used!",making:false });
      } else {
        var dob = String(
          this.state.year + this.state.month + this.state.date
        );
        var userdata = this.state;
        const newuser = {
          username: userdata.username,
          password: userdata.password,
          dob: dob,
          disname: userdata.disname,
        };
        this.register(newuser);
      }
      })
       .catch(err=>{console.log(err)})
      }
    }
  };

  changeuser = event => {
    this.setState({incomplete:""})
    if(event.target.name==="disname"&&event.target.value.length>12){}
    else this.setState({ [event.target.name]: event.target.value });
    if(event.target.name==="username"){
      var at = false
      var dot = false
      var lastcheck = false
      var double = false
      var blankcheck = true

      for (let i=0; i<event.target.value.length; i++){
        if(dot){
          lastcheck=true
        }
        if(event.target.value[i]==="@" && at){
          double = true
        }
        if(event.target.value[i]==="@"&&!dot&&event.target.value[i+1]!=="."&&i!==0){
          at = true
        }
        if(event.target.value[i]==="." && at ){
          dot = true
        }if(event.target.value[i]===" " && i!==event.target.value.length-1){
          blankcheck=false
        }
      }
      if(event.target.value.length<7 ){
        lastcheck=false
      }
      if(at && lastcheck && !double && blankcheck){
        this.setState({mailcolor: green})
      } else{
        this.setState({mailcolor: red})
      }
    }
    if(event.target.name==="password"){
      let secure = false
      let secure1 = false
      var letter = /^[a-zA-Z]+$/
      var number = /^[0-9]+$/
      for (let i=0; i<event.target.value.length; i++){
        if(event.target.value[i].match(letter)){
          secure = true
        }
        if(event.target.value[i].match(number)){
          secure1 = true
        }
      }
      if(event.target.value.length>4 && secure && secure1){
        this.setState({pswcolor: green})
      } else if(event.target.value.length>4 || (secure && secure1)){
        this.setState({pswcolor: yellow})
      } else{
        this.setState({pswcolor: red})
      }
      if(event.target.value===this.state.repassword){
        this.setState({repswcolor: green})
      } else{
        this.setState({repswcolor: red})
      }
    }
    if(event.target.name==="repassword"){
      if(event.target.value===this.state.password){
        this.setState({repswcolor: green})
      } else{
        this.setState({repswcolor: red})
      }
    }
    if(event.target.name==="payinfo"){
      if(event.target.value.length===8){
        this.setState({paycolor: green})
      } else if(event.target.value===""){
        this.setState({paycolor: "255,255,255"})
      } else{
        this.setState({paycolor: red})
      }
    }
    if (event.target.name === "month") {
      if (event.target.value !== "") {
        if (event.target.value === "02") {
          this.setState({ monthinput: false,monthinput28:true,monthinput30:false });
        } else if (
          event.target.value === "04" ||
          event.target.value === "06" ||
          event.target.value === "09" ||
          event.target.value === "11"
        ) {
          this.setState({ monthinput30: true });
          this.setState({ monthinput: false });
          this.setState({ monthinput28: false });
        } else {
          this.setState({ monthinput: true });
          this.setState({ monthinput28: false });
          this.setState({ monthinput30: false });
        }
      } else {
        this.setState({ monthinput: false });
        this.setState({ monthinput28: false });
        this.setState({ monthinput30: false });
      }
    }
  };

  render() {
    return (
      <div className="gradientBG p-2" style={{minHeight:"100vh"}}>
        <div style={{height:"15vh"}}/>
        <Form
          confirm={this.confirm}
          changeuser={this.changeuser}
          changedate={this.changedate}
          state={this.state}
          making={this.state.making}
          done={this.state.done}
        />
      </div>
    );
  }
}

export default Body1

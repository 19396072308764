import enMessages from "./en";
import jaMessages from "./ja";
import deMessages from "./de";

export const languageMapping = {
  en: {
    messages: enMessages
  },
  ja: {
    messages: jaMessages
  },
  de: {
    messages: deMessages
  }
};

export const getLocaleMessages = language => languageMapping[language].messages;
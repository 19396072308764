import cardReturn from "../Home/Play/Battle/data/cardReturn";

var arr = [
  cardReturn("Zombie"),
  cardReturn("Boi"),
  cardReturn("Gurl"),
  cardReturn("Aqua")
];
arr[0].equip = 1;
arr[1].equip = 2;
arr[2].equip = 3;

export const demoUser = {
  history: [
    { message: null, quality: "good", hours: 4.4, date: "2/15" },
    { message: null, quality: "good", hours: 7.8, date: "2/16" },
    { message: null, quality: "bad", hours: 2.2, date: "2/17" },
    { message: null, quality: "ok", hours: 5.6, date: "2/18" },
    { message: null, quality: "ok", hours: 3, date: "2/19" },
    {
      message: "Yesterday you did a good job! Let's keep it up!",
      quality: "good",
      hours: 6.6,
      date: "2/20"
    }
  ],
  friends: ["admin", "contact.naomori@gmail.com"],
  point: { coin: 2000, gem: 25, rank: 1, exp: [3, 1], total: 55 },
  lastlogin: 20191007,
  status: "before",
  items: {
    cards: arr,
    buffs: [
      { name: "Health Potion", fname: "Common", power: 92, type: "HP" },
      { name: "Power Potion", fname: "Rare", power: 65, type: "AP" }
    ]
  },
}

export const demoChat = [
  {
    username: "admin",
    read: 2,
    chat: [
      ["Hello, Guest! This is Motimanager Bot.", "R", "12:15", 0],
      ["You are currently offline. Please sign up to start chatting with other users!", "R", "12:15", 0]
    ]
  },
  {
    username: "nao",
    read: 0,
    chat: [
      ["Example Friend added you.", "R", "12:15", 0]
    ]
  },
  {
    username: "ken",
    read: 1,
    chat: [
      ["You added Example Friend2.", "S", "12:15", 0],
      ["2/20/2020","C", "13:02", 0],
      ["I'm much more productive than last week. Motimanager is amazing!", "R", "13:02", 0]
    ]
  }
];

export const demoFriends = [
  {
    username: "admin",
    disname: "Motimanager Bot",
    icon: "Robot",
    message: "I'm here to help!",
    online: true,
    rank: 0
  },
  {
    username: "nao",
    disname: "Example Friend",
    icon: "Zombie",
    message: "Let's be friends!",
    online: false,
    rank: 8
  },
  {
    username: "ken",
    disname: "Example Friend2",
    icon: "Gurl",
    message: "Let's be productive!",
    online: false,
    rank: 4
  }
];

export const demoProfile = {
  username: "demo", disname: "Guest", icon: "Boi", rank: 1, message: "Hello, I'm using Motimanager!", house: null,
}

import React from "react";
import {injectIntl, FormattedMessage} from "react-intl"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faGamepad, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { Dashboard } from '@material-ui/icons';
import { Link } from "react-router-dom";

const h6Style = {fontSize:"10px", padding:"3px",marginBottom:"35px"}

function NavSide() {
  return (
    <div className="sidebar text-center">
      <div style={{minHeight:"50px"}}>
        <Link to="/">
          <img alt="dashboard" style={{width:"50px",height:"auto",marginTop:"10px"}} src='/icons/motiicon.png'/>
        </Link> 
        </div>
        <div style={{height:"40px"}}/>
        <div className="p-1">
          <Link className="customLink2" to="/home">
            <Dashboard fontSize="large" />
            <h6 style={h6Style}>Dashb..</h6>
          </Link> 
          <Link className="customLink2" to="/work">
            <FontAwesomeIcon size="1x" icon={faBriefcase} style={{fontSize:"30px"}}/>
            <h6 style={h6Style}>Work</h6>
          </Link> 
          <Link className="customLink2" to="/play">
            <FontAwesomeIcon size="lg" icon={faGamepad} style={{fontSize:"28px"}}/>
            <h6 style={h6Style}>Play</h6>
          </Link> 
          <Link className="customLink2" to="/account">
            <FontAwesomeIcon size="lg" icon={faUserCircle} style={{fontSize:"28px"}}/>
            <h6 style={h6Style}>
              <FormattedMessage id="account"/>
            </h6>
          </Link> 
        </div>
    </div>
    
  );
}

export default injectIntl(
  NavSide
)
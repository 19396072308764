import React from "react"
import Container from "react-bootstrap/Container"

export default function ServerDown(){
    return(
        <Container className="text-center">
            <h2>Server is currently down...</h2>
            <h2>We're sorry for your inconvinience.</h2>
        </Container>
    )
}
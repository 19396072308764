import React, { useState, useCallback, useEffect } from "react";
import CardUI from "../Battle/CardUI";


const Inventory = props =>{
  const [cards, setCards] = useState([])
  const [buffs, setBuffs] = useState([])
  const [, updateState] = React.useState();

  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(()=>{
    let temp = props.cards
    let temp2 = props.buffs
    for(let i=0; i<temp.length; i++){
      temp[i].selling = false
    }
    for(let i=0; i<temp2.length; i++){
      temp2[i].selling = false
    }
    setCards(temp)
    setBuffs(temp2)
  },[props.selling, props.cards, props.buffs])

  const cardlist=()=> {
    return cards.map((card, key) => {
      return (
        <div className="p-0 m-2" key={key}>
          <div 
          className={props.selling && card.equip ? "card-game unable" : props.selling?"card-game hoverimage":"card-game"} style={{ margin:"auto" }}
            onClick={()=>{
            if(props.selling && !card.equip){
              card.selling = !card.selling
              props.select(cards,buffs)
              forceUpdate()
            }
          }}
          >
            <CardUI card={card} selling={props.selling} />
          </div>
        </div>
      );
    });
  }

  const bufflist =()=> {
    return buffs.map((buff, key) => {
      return (
        <div className="p-0 m-2" key={key}>
          <div className="card-game pb-2" style={{cursor:props.selling?"pointer":null,margin:"auto",width:"150px"}}
          onClick={()=>{
            if(props.selling){
              buff.selling = !buff.selling
              props.select(cards,buffs)
              forceUpdate()
            }
          }}
          >
            <CardUI card={buff} buff={true} selling={props.selling} />
          </div>
        </div>
      );
    });
  }

  return (
    <div className="pt-2">
      {cards?
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>{cardlist()}</div>:
      <h3>No Card</h3>}
      <h2 className="mt-4 mb-3">Items</h2>
      {buffs?
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>{bufflist()}</div>
      :<h3>No Item</h3>}
    </div>
  );
}

export default Inventory;
var audio = new Audio('/sound/Boi.mp3')

const SE = {
  Boi:new Audio('/sound/Boi.mp3'),
  Skeleton:new Audio('/sound/Skeleton.mp3'),
  Zombie:new Audio('/sound/Zombie.mp3'),
  Zombie2:new Audio('/sound/Zombie2.mp3'),
  Aqua:new Audio('/sound/Aqua.mp3'),
  Aqua2:new Audio('/sound/Aqua2.mp3'),
  Witch:new Audio('/sound/Witch.mp3'),
  Witch2:new Audio('/sound/Witch2.mp3'),
  Gurl:new Audio('/sound/Gurl.mp3'),
  Gurl2:new Audio('/sound/Gurl2.mp3'),
  Gurl3:new Audio('/sound/Gurl3.mp3'),
  Ghost:new Audio('/sound/Ghost.mp3'),
  Ghost2:new Audio('/sound/Ghost2.mp3'),
  Elekt:new Audio('/sound/Elekt.mp3'),
  Elekt2:new Audio('/sound/Elekt2.mp3'),
  Therapist:new Audio('/sound/Therapist.mp3'),
  Therapist2:new Audio('/sound/Therapist2.mp3'),
  Mage:new Audio('/sound/Mage.mp3'),
  Mage2:new Audio('/sound/Mage2.mp3'),
  Golem:new Audio('/sound/Golem.mp3'),
  Golem2:new Audio('/sound/Golem2.mp3'),
  Necro:new Audio('/sound/Necro.mp3'),
  Wizzard:new Audio('/sound/Wizzard.mp3'),
  Wizzard2:new Audio('/sound/Wizzard2.mp3'),
  Dragon:new Audio('/sound/Dragon.mp3'),
  Dragon2:new Audio('/sound/Dragon2.mp3'),
  Aliados:new Audio('/sound/Aliados.mp3'),
  Aliados2:new Audio('/sound/Aliados2.mp3'),
  Aliados3:new Audio('/sound/Aliados3.mp3'),
}

export const damaging =({ attackCard, defendName, remainMana, double, teamHealable })=>{
    let damage = Math.floor(Math.random() * (attackCard.ap2 - attackCard.ap)) + attackCard.ap;
    let manaPowerUp = remainMana > 7 ? 7 : remainMana
    if(attackCard.name === "Aliados") damage += (manaPowerUp / 2) * damage;
    if(double) damage *= 2;
    damage = Math.round(damage);
    let ran = Math.floor(Math.random()* (attackCard.name === "Aliados" ? 5 : 4))
    let logTemp = attackCard.name+" deals "+damage+" damage to "+defendName+"!"
    let hip = " damage to "+defendName+"!"
    let therapy=0
    let ocean=0
    let costReduction=0
    let costCurse=0
    let area=0
    let zombie= false
    let hiddenhp=0
    let hiddenap=0
    let summon;
    audio.pause()
    audio.currentTime = 0;
    audio = SE[attackCard.name]

    if(ran===0 && attackCard.name!=="Boi" && attackCard.name!=="Necro" && attackCard.name!=="Skeleton"){
      audio = SE[attackCard.name+"2"]
      if(attackCard.name==="Ghost") {damage*=2;logTemp="Ghost used Hell Arm! "+damage+hip}
      else if(attackCard.name==="Golem") {
        damage=attackCard.ap2*2;
        logTemp="Golem used Rock Punch! "+damage+hip
      }
      else if(attackCard.name==="Mage") {attackCard.nowhp+=damage;logTemp="Mage used Drain Beam! Drained "+damage+" HP from "+defendName+"!" }
      else if(attackCard.name==="Therapist" && teamHealable){logTemp="Therapist used Therapy! Team healed "+damage+" damage!";therapy=damage;damage=0;}
      else if(attackCard.name==="Gurl"){
        let ran = Math.floor(Math.random()*3)
        if(ran === 0){
          damage=attackCard.ap2*2
          logTemp="Gurl used SANG! "+damage+hip
          audio = SE[attackCard.name+"3"]
        } else {
          damage=0
          logTemp="Gurl used SANG! Nothing happened!"
        }
      } else if(attackCard.name==="Zombie"&&attackCard.nowhp!==attackCard.hp){
        damage = Math.round(damage*3)
        logTemp = "Zombie used Replication! Recovered "+damage+" damage!"
        attackCard.nowhp+=damage
        damage = 0
        zombie=true
      } else if(attackCard.name==="Aqua"){
        logTemp = "Aqua used Ocean Eye! "+damage+" damage to "+defendName+" and generated 3 Mana!"
        ocean = 3
      } else if(attackCard.name==="Dragon"){
        area = Math.round(damage*0.75)
        logTemp="Dragon used Fire Breath! "+area+" damage to the team!"
        damage = 0
      } else if(attackCard.name==="Elekt"){
        costReduction = 1;
        logTemp="Elekt used Overload! " +damage+hip
      } else if(attackCard.name==="Witch"){
        ran = !teamHealable ? 0 : Math.floor(Math.random()*2)
        if(ran===0) {
          hiddenap=Math.round(damage*0.75)
          damage=0
        } else {
          hiddenhp=Math.round(damage*2)
          damage=0
        }
      } else if(attackCard.name==="Wizzard") {
        costCurse = 1;
        logTemp="Wizzard used Frozen Orb! "+damage+hip
      } else if(attackCard.name==="Aliados") {
        damage=Math.round(damage*1.5)
        logTemp="Aliados used Deadly Slash! "+damage+" damage to "+defendName+"!"
      }
    } else if(attackCard.name==="Aliados" && ran===1) {
      area=Math.round(damage*0.67)
      logTemp="Aliados used Spider Army! "+area+" damage to the entire team!"
      damage = 0
    } else if(attackCard.name==="Aliados" && ran===2) {
      logTemp="Aliados used Revive the Dead! Skeleton was summoned";
      summon = {
        name:"Skeleton", hp:Math.round(damage / 1.5),
        nowhp:Math.round(damage / 1.5),
        ap:Math.round(damage / 3),ap2:Math.round(damage / 3 + 100),cost:0,used:true
      }
      damage = 0
    }
    audio.volume=0.1
    audio.play()
    return { 
      damage: damage, hp: attackCard.nowhp, costReduction, costCurse, summon,
      therapy: therapy, ocean: ocean, area: area, zombie: zombie, hiddenhp: hiddenhp, hiddenap: hiddenap, log: logTemp 
    }
  }
import React, { useState } from "react"
import axios from "axios";
import {injectIntl, FormattedMessage} from "react-intl"
import { Button } from "react-bootstrap"
import api from "../../api"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem, faCoins } from '@fortawesome/free-solid-svg-icons'
import EditQuest from "./Module/EditQuest";

const defaultDaily = [
  { description: "Login to Motimanager" }, { description: "Get to work" }, { description: "Have a good break" }, { description: "Complete 3 tasks" }, 
  { description: "Open some chest in Play Mode" }
];
const defaultWeekly = [{ description: "Complete 10 tasks" }, { description: "Do your favorite thing" }, { description: "Take a day off" }];

const Quest=({ user, point, claim })=>{
  const [dailyQuest, setDailyQuest] = useState(user.quests ? user.quests.daily : defaultDaily);
  const [weeklyQuest, setWeeklyQuest] = useState(user.quests ? user.quests.weekly : defaultWeekly);
  const [openEdit, setOpenEdit] = useState(false);

  const questStyle = {padding:"10px 0px",margin:"0",fontWeight:"bold"}
  return(
    <div className="text-left card-round">
        <div className="card-top" style={{ backgroundColor:"rgb(66, 190, 163)", display: "flex", width: "100%", justifyContent: "space-between" }}>
          <div>
            Quest
          </div>
          <div>
            <Button variant={"light"}
              style={{fontWeight:"bold",fontSize:"14px",padding:"2px 10px",width:"120px" }}
              onClick={()=>{
                setOpenEdit(true);
              }}
            >
              Customize
            </Button>
          </div>
        </div>
        <div className="card-bottom" style={{padding:"4px 10px"}}>
        <h6 style={questStyle}>
          <FormattedMessage id="dailyquest"/>
        </h6>
        {dailyQuest.map((quest,key)=>{
          let getcoin = 500
          let getgem = 0
          if(key === 1){
            getcoin = 0
            getgem = 3
          }
          if(key === 2){
            getcoin = 800
            getgem = 0
          }
          if(key === 3){
            getcoin = 0
            getgem = 5
          }
          if(key === 4){
            getcoin = 1000
            getgem = 0
          }
          return (
            <div className="quest-card" key={key} >
              <p className="mb-3 font-weight-bold">
                {quest.description}
              </p>
              <div className="d-flex">
                <div className="d-flex ml-auto mr-4">
                  <h6>
                    <FontAwesomeIcon icon={getgem?faGem:faCoins} className={getgem?"text-blue":"text-yellow"}/> {getgem?getgem:getcoin}
                  </h6>
                </div>
                <div className="d-flex">
                  <Button variant={quest.claimed ? "secondary" : "info"}
                    style={{fontWeight:"bold",fontSize:"14px",padding:"2px 10px",width:"120px",opacity: !quest.claimed ? "1":"0.7"}}
                    onClick={()=>{
                      if(!quest.claimed){
                        let q = dailyQuest
                        q[key].claimed = true;
                        setDailyQuest(q);
                        claim({ point: { coin: point.coin+getcoin, gem: point.gem+getgem, rank: point.rank }, quests: { daily: q, weekly: weeklyQuest } })
                      }
                    }}
                  >
                    {!quest.claimed ? "CLAIM":"CLAIMED"}
                  </Button>
                </div>
              </div>
            </div>
          )
        })}
        
        <h6 style={questStyle}>
          <FormattedMessage id="weeklyquest"/>
        </h6>
        {weeklyQuest.map((quest,key)=>{
          let getcoin = 3000
          let getgem = 0
          if(key === 1){
            getcoin = 0
            getgem = 15
          }
          if(key === 2){
            getcoin = 0
            getgem = 20
          }
          return (
            <div className="quest-card" key={key} >
              <p className="mb-3 font-weight-bold">
                {quest.description}
              </p>
              <div className="d-flex">
                <div className="d-flex ml-auto mr-4">
                  <h6>
                    <FontAwesomeIcon icon={getgem?faGem:faCoins} className={getgem?"text-blue":"text-yellow"}/> {getgem?getgem:getcoin}
                  </h6>
                </div>
                <div className="d-flex">
                  <Button variant={quest.claimed ? "secondary" : "info"}
                    style={{fontWeight:"bold",fontSize:"14px",padding:"2px 10px",width:"120px",opacity: !quest.claimed ? "1":"0.7"}}
                    onClick={()=>{
                      if(!quest.claimed){
                        let q = weeklyQuest
                        q[key].claimed = true;
                        setWeeklyQuest(q);
                        claim({ point: { coin: point.coin+getcoin, gem: point.gem+getgem, rank: point.rank }, quests: { weekly: q, daily: dailyQuest } })
                      }
                    }}
                  >
                    {!quest.claimed ? "CLAIM":"CLAIMED"}
                  </Button>
                </div>
              </div>
            </div>
          )
        })}
        <h6 style={{textAlign:"right",fontSize:"14px"}}>*Resets on Sunday</h6>
        </div>
        <EditQuest open={openEdit} onHide={()=>setOpenEdit(false)} 
        save={(data)=> {
          setDailyQuest(data.daily);
          setWeeklyQuest(data.weekly);
          claim({ quests: data });
          setOpenEdit(false);
        }} 
        quests={{ daily: dailyQuest, weekly: weeklyQuest }}
        />
    </div>
  )
}

export default injectIntl(Quest);
import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faGamepad, faComments } from '@fortawesome/free-solid-svg-icons'

const Intro = props => {
    const styleImage={width:"100%",height:"auto", minWidth:"300px", boxShadow:"0px 1px 3px 3px rgba(0, 0, 0, 0.1)"}
    const styleText={width:"100%",height:"auto", minWidth:"300px", fontSize:"17px",fontWeight:"bold", padding:"10px 2px"}

    return(
        <div className="text-center" style={{minHeight:"100vh", backgroundColor:"rgb(252,252,252)"}}>
            <h1 className="pt-4 font-weight-bold resize-h1" style={{overflowWrap: "break-word"}}>WORK + FUN + FRIENDS = PRODUCTIVE</h1>
            <Container style={{maxWidth:"1000px",minHeight:"82vh", padding:"25px 0", color:"rgb(60,60,60)"}}>
                <Row className="m-0">
                    {props.imageLoad?
                    <Col>
                        <Image style={styleImage} src="/screenshots/motimanager-screenshot2.jpg"/>
                    </Col>
                    :null}
                    <Col>
                        <div>
                        <h2 className="pt-4">WORK <FontAwesomeIcon icon={faBriefcase}/></h2>
                        <div style={styleText}>Simple task manager and work timer to support your work. As you work, you will be awarded by coins and gems by completing quests!</div>
                        </div>
                    </Col>
                </Row>
                <p/>
                <Row className="m-0">
                    {props.imageLoad?
                    <Col className="d-block d-sm-none">
                        <Image style={styleImage} src="/screenshots/motimanager-screenshot4.jpg"/>
                    </Col>
                    :null}
                    <Col>
                        <h2 className="pt-4">GAME <FontAwesomeIcon icon={faGamepad}/></h2>
                        <div style={styleText}>We have an Auto-Battler Game that is not a pay-to-win but a Work-To-Win! Spend your coins and gems earned by working to build your deck. You will get 'Productivity Stars' when you win!</div>
                    </Col>
                    {props.imageLoad?
                    <Col className="d-none d-sm-block" >
                        <Image style={styleImage} src="/screenshots/motimanager-screenshot4.jpg"/>
                    </Col>
                    :null}
                </Row>
                <p/>
                <Row className="m-0">
                    {props.imageLoad?
                    <Col>
                        <Image style={styleImage} src="/screenshots/motimanager-screenshot3.jpg"/>
                    </Col>
                    :null}
                    <Col>
                        <h2 className="pt-4">SOCIAL <FontAwesomeIcon icon={faComments}/></h2>
                        <div style={styleText}>Connect and communicate with your friends effortlessly. You will compete with friends and other users on how many 'Productivity Stars' you have!</div>
                    </Col>                
                </Row>
                <p/>
            </Container>
            <div className="gradientBG text-center pt-5 pb-2" style={{minHeight:"18vh"}}>
                <h3 style={{color:"white",marginBottom:"20px"}}>Let's Get Started!</h3>
                <a href="/home" className="btn-green" style={{width:"250px",fontSize:"18px",padding:"10px 25px"}}>Start Now</a>

                <div className="mt-5" style={{ color:"white" }}>© 2020 Created by Nao Mori</div>
            </div>
        </div>
    )
}

export default Intro
import React, { useState, useEffect, useCallback } from "react"
import { injectIntl} from "react-intl";
import { Row, Col, Button, ProgressBar, Image } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint, faHandRock, faCoins, faStar, faBolt } from '@fortawesome/free-solid-svg-icons'
import rarityReturn from "../Craft/rarityReturn";
import { damaging } from "./data/damaging"
import { Tooltip, withStyles } from "@material-ui/core";
import CardHud from "./data/cardHud";

let automated = true;

var cardUse = [false,false,false]
var round = 0
let manaTemp = 0;
let manaRegen = 0;
var yourTurnSaved = true

const StyledTooltip = withStyles({
  tooltip: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f5f5f9'
  }
})(Tooltip);

const doubleRound = 15

const Game = ({ iniDeck, iniAiDeck, profile, point, aiProfile, reward, gameover, close }) => {
  var regen = 0
  const [started, setStarted] = useState(0)
  const [mana, setMana] = useState(0)
  const [aiMana, setAiMana] = useState(0)
  const [yourTurn, setYourTurn] = useState(true)
  const [log, setLog] = useState("");
  const [regenMP, setRegenMP] = useState(null);
  const [leftover, setLeftOver] = useState(null);
  const [end, setEnd] = useState(false);
  const [deck, setDeck] = useState([undefined,undefined,undefined]);
  const [aiDeck, setAiDeck] = useState([undefined,undefined,undefined]);
  const [rank, setRank] = useState(point.rank);
  const [prize, setPrize] = useState(0);
  const [win, setWin] = useState(false);
  const [defended, setDefended] = useState(0);
  const [attacked, setAttacked] = useState(0);
  const [, updateState] = useState();

  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(()=>{
    round = 0
    yourTurnSaved = true;
    let deckTemp = []
    for(let i = 0; i < iniDeck.length; i++){
      if(iniDeck[i]) deckTemp.push({ ...iniDeck[i], nowhp: iniDeck[i].hp, originalCost: iniDeck[i].cost })
      else deckTemp.push(undefined)
    }
    setDeck(deckTemp)
    let deckTemp2 = []
    for(let i = 0; i < iniAiDeck.length; i++){
      if(iniAiDeck[i]) deckTemp2.push({ ...iniAiDeck[i], nowhp: iniAiDeck[i].hp, originalCost: iniAiDeck[i].cost })
      else deckTemp2.push(undefined)
    }
    setAiDeck(deckTemp2)
    regenaration(0)
    setLog(`${profile.disname} turn,`);
    setStarted(started + 1);
  },[])

  useEffect(()=>{
    if(automated && started) aiCardSelect();
  },[started])

  const regenaration = (left) =>{
    rank<10? regen = Math.floor(Math.random()*4)+1:
    rank<20? regen = Math.floor(Math.random()*5)+2:
    rank<30? regen = Math.floor(Math.random()*7)+3:
    regen = Math.floor(Math.random()*8)+4;
    round ++
    manaTemp = regen + left;
    manaRegen = regen;
    setMana(manaTemp)
    setRegenMP(regen);
  }

  const aiCardSelect = () => {
    setTimeout(()=>{
      let attackNum = undefined;
      let d = yourTurnSaved ? deck : aiDeck;
      for(const i in d) {
        if(d[i] && !d[i].used && d[i].cost <= manaTemp) {
          attackNum = Number(i);
          break;
        }
      }
      if(attackNum !== undefined) onAttack(d[attackNum], attackNum);
      else endTurn(manaTemp)
    }, 1500)
  }

  const endTurn = manaLeft => {
    setDefended(0)
    setAttacked(0)
    manaLeft = manaLeft > 3 ? 3 : manaLeft;
    if(yourTurnSaved){
      cardUse = [false, false, false]
      setAiMana(manaRegen + manaLeft)
      manaTemp = manaRegen + manaLeft
      setLog(`${aiProfile.disname}'s turn,`)
      setLeftOver(manaLeft);
      if(!automated) aiCardSelect();
    } else {
      regenaration(manaLeft)
      if(round === doubleRound){
        setLog(`Damage / healing is doubled from now on! ${profile.disname} turn,`);
      } else setLog(`${profile.disname} turn,`);
      setLeftOver(manaLeft);
    }
    let defendDeck = yourTurnSaved ? aiDeck : deck;
    for(let i=0;i<3;i++){
      if(defendDeck[i]){
        delete defendDeck[i].used;
      }
    }
    if(yourTurnSaved) setAiDeck(defendDeck);
    else setDeck(defendDeck);
    setYourTurn(!yourTurnSaved)
    yourTurnSaved = !yourTurnSaved
    forceUpdate()
    if(automated) setStarted(started + 1);
  }

  const onAttack=(card, key)=>{
    let defendDeck
    let attackDeck
    let addNum = 4
    let addNum2 = 1

    if(yourTurnSaved){
      attackDeck = [...deck]
      defendDeck = [...aiDeck]
      addNum = 1
      addNum2 = 4
    } else {
      attackDeck = [...aiDeck]
      defendDeck = [...deck]
    }
    let num = 100

    while(!defendDeck[num]){
      num = Math.floor(Math.random()*3)
    }

    let result = damaging({ 
      attackCard: card, defendName: defendDeck[num].name, remainMana: manaTemp, double: round >= doubleRound,
      teamHealable: attackDeck.filter((card)=> card && card.hp !== card.nowhp).length > 0,
    })
    if(yourTurnSaved){
      manaTemp = manaTemp - card.cost
    } else {
      if(attackDeck[key].name === "Aliados") manaTemp = 0
      else manaTemp = manaTemp - card.cost
    }

    setLog(result.log)
    defendDeck[num].nowhp -= result.damage
    attackDeck[key].nowhp = result.hp
    attackDeck[key].used = true;
    if(attackDeck[key].nowhp > attackDeck[key].hp){
      attackDeck[key].nowhp = attackDeck[key].hp
    }
    setAttacked(key + addNum2)
    if(result.therapy){
      for(let i=0;i<3;i++){
        if(attackDeck[i]){
          attackDeck[i].nowhp += result.therapy
          if(attackDeck[i].nowhp > attackDeck[i].hp){
            attackDeck[i].nowhp = attackDeck[i].hp
          }
        }
      }
      setDefended(0)
    } else if(result.ocean){
      manaTemp += result.ocean;
      setDefended(num + addNum)
    } else if(result.area){
      for(let i=0;i<3;i++){
        if(defendDeck[i]){
          defendDeck[i].nowhp -= result.area
        }
      }
      setDefended(yourTurnSaved? 7 : 8)
    } else if(result.hiddenhp) {
      let buffed = 0
      let highest = 0
      for(let i=0;i<3;i++){
        if(attackDeck[i]) {
          if(highest <= (attackDeck[i].hp-attackDeck[i].nowhp)){
            buffed = i
            highest = attackDeck[i].hp-attackDeck[i].nowhp
          }
        }
      }
      attackDeck[buffed].nowhp += result.hiddenhp
      if(attackDeck[buffed].nowhp > attackDeck[buffed].hp) attackDeck[buffed].nowhp = attackDeck[buffed].hp
      if(yourTurnSaved) setDefended(buffed + 1)
      else setDefended(buffed + 5)
      setLog("Witch used Hidden Book! "+attackDeck[buffed].name+" healed "+result.hiddenhp+" damage!")
    } else if(result.hiddenap) {
      let buffed = 1000
      while(!attackDeck[buffed]){
        buffed = Math.floor(Math.random()*attackDeck.length)
      }
      attackDeck[buffed].ap += result.hiddenap
      attackDeck[buffed].ap2 += result.hiddenap
      setLog("Witch used Hidden Book! "+attackDeck[buffed].name+"'s AP was increased by "+result.hiddenap+"!")
      setDefended(buffed + addNum2)
    } else if(result.zombie) setDefended(0)
    else if(result.costReduction) {
      if(attackDeck[key].cost > 0) attackDeck[key].cost -= result.costReduction;
    } else if(result.costCurse) {
      defendDeck[num].cost += result.costCurse;
    } else if(result.summon) {
      for(let i=0;i<3;i++){
        if(attackDeck[i] === undefined){
          attackDeck[i] = result.summon;
          break;
        }
      }
    } else {
      if(result.zombie) setDefended(0)
      else setDefended(num + addNum)
    }

    for(let i=0;i<3;i++){
      if(defendDeck[i]){
        if(defendDeck[i].nowhp<1) {
          if(defendDeck[i].name==="Necro") defendDeck[i] = { name:"Skeleton",hp:Math.round(defendDeck[i].hp/2),nowhp:Math.round(defendDeck[i].hp/2),ap:Math.round(defendDeck[i].ap/2),ap2:Math.round(defendDeck[i].ap2/2),cost:1}
          else defendDeck[i] = undefined
        }
      }
      setLeftOver(null);
    }

    if(yourTurnSaved) {
      setAiDeck(defendDeck);
      setDeck(attackDeck);
    } else {
      setAiDeck(attackDeck);
      setDeck(defendDeck);
    }

    if(!defendDeck[0] && !defendDeck[1] && !defendDeck[2]) {
      gameEnd(yourTurnSaved);
    } else {
      if(yourTurnSaved) setMana(manaTemp)
      else {
        setAiMana(manaTemp)
      }
      setStarted(started + 1)
    }
  }

  const gameEnd = (ifWon) => {
    let add = 0
    let prized = 0
    let logTemp = "You Lose!"
    if(!aiProfile.player) {
      if(ifWon){
        add = aiProfile.boss ? 0 : 1
        prized = aiProfile.boss ? 1000+(reward.bossRank * 200) : 500
        logTemp = "You Win!!"
      } else {
        if(!aiProfile.boss && rank > 20) add = -1
        if(!aiProfile.boss) prized = 200;
      }
    } 
    if(reward.mob < 0 && !aiProfile.boss) prized = 0;
    gameover(rank + add, prized)
    setRank(rank + add)
    setYourTurn(false)
    setTimeout(()=> {
      setEnd(true)
      setWin(ifWon)
      setLog(logTemp)
      setPrize(prized)
    },1000)
  }

  const Deck=(ifYou)=>{
    return (ifYou ? deck : aiDeck).map((card,key)=>{
      if(ifYou) key += 4;
      else key += 1;
      if(card) return (
        <Col className="mb-2" key={key}>
          <StyledTooltip 
          title={(
            <div style={{ padding: "5px", fontFamily: "Montserrat" }}>
              <div style={{ fontSize: "20px", lineHeight: "18px", marginBottom: 7 }}><FontAwesomeIcon size="1x" icon={faBolt} className="text-orange"/> {CardHud(card.name).special}</div>
              <div style={{ fontSize: "16px", lineHeight: "18px", marginBottom: 7 }}>{CardHud(card.name).sp}</div>
              {card.name !== "Aliados" && card.name !== "Boi" && card.name !== "Necromancer" && card.name !== "Skeleton" ? (
                <div style={{ fontSize: "14px", lineHeight: "15px" }}>*Special happens by a 25% chance when attacking</div>
              ) : null}
            </div>
          )}
          >
            <div 
            className={
              ifYou && !automated && yourTurn && mana>=card.cost && !card.used?"card-game m-auto border-orange": 
              !automated && yourTurn?"card-game m-auto unable" : "card-game m-auto"
            }
            onClick={()=>{
            if(ifYou && !automated && yourTurn && mana>=card.cost && !card.used) {
              cardUse[key] = true
              onAttack(card, key);
            }
            }}
            style={{ 
              boxShadow: attacked === key ? "0px 0px 15px 15px rgba(0, 255, 106, 0.4)": defended === key ? "0px 0px 15px 15px rgba(228, 36, 2, 0.4)" : null,
            }}>
                <Image
                  rounded
                  className={rarityReturn(card.name)}
                  src={`/images/cards/${card.name}.png`}
                  style={{width: "100%"}}
                />
                <h5 className="mb-1 mt-1">{card.name} <FontAwesomeIcon size="1x" icon={faTint} className="text-blue"/> 
                {" "}<b style={{ color: card.cost > card.originalCost ? "rgb(200,0,0)" : card.cost < card.originalCost ? "rgb(0,200,0)" : "inherit" }}>{card.name==="Aliados"?"??":card.cost}</b>
                </h5>
                <p className="mt-1 mb-2" style={{fontSize:"14px"}}><FontAwesomeIcon icon={faHandRock} className="text-yellow"/> {card.name==="Aliados"?"???":`${card.ap}-${card.ap2}`}</p>
                <div className="position-relative">
                  <ProgressBar striped variant="danger" style={{height:"25px",backgroundColor:"black"}} now={(card.nowhp/card.hp)*100} />
                  <div style={{position:"absolute", top:"0", width:"100%"}}>
                    <div style={{fontWeight:"900", color:"white", textShadow:"-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black", textAlign:"center"}}>
                      {Math.round(card.nowhp)}/{card.hp}
                    </div>
                  </div>
                </div>
            </div>
          </StyledTooltip>
        </Col>
      )
      else return <Col key={key}/>
    })
  }
  return(
    <div style={{maxWidth:"1200px",margin:"auto", minHeight: "100vh", paddingTop: "7vh" }}>
      {end?
        <div className="mt-5">
          <h1 className="pb-4 pt-4">{log}</h1>
          <h3>
            <FontAwesomeIcon icon={faCoins} className="text-yellow"/> {prize} <FontAwesomeIcon icon={faStar} className="text-orange"/> {rank} 
            {" "}{aiProfile.boss || aiProfile.player ?"(Keep!)":win?"(Up!)":rank<20?"(Keep!)":"(Down!)"}
          </h3>
          <Button className="m-4" variant="secondary" 
          onClick={()=> {
            close(rank);
          }}>Go Back</Button>
        </div>
      :
      <div>
          <Row className="m-0" style={{ minHeight: "270px" }}>
            <Col>
              <div className="p-3 m-auto text-light mb-2" style={{maxWidth:"200px", boxShadow: !yourTurn?"0px 0px 15px 15px rgba(255, 174, 0, 0.4)":null,borderRadius:"20px", backgroundColor:"#3e2723"}}>
                <Image className="bg-light" roundedCircle src={"/images/"+aiProfile.icon+".png"} style={{width:"100px",height:"100px", margin:"0 auto"}}/>
                <h4 className="mt-2">{aiProfile.disname}</h4>
                {!yourTurn ? <h4><FontAwesomeIcon icon={faTint} className="text-blue"/> {aiMana}</h4> : null}
              </div>
            </Col>
            {Deck(false)}
          </Row>
        <div className="mt-3 mb-3" style={{ minHeight: "52px" }}>
          <Row className="m-0">
            <Col lg="3" md="3">
              {!automated && yourTurn ?
                <Button
                className="mb-2"
                style={{fontSize:"20px",fontWeight:"bold"}}
                variant={mana<(deck[0]===undefined || cardUse[0] ? 500:deck[0].cost)&& mana<(deck[1]===undefined || cardUse[1]?500:deck[1].cost)&&
                mana<(deck[2]===undefined || cardUse[2]?500:deck[2].cost)?"warning":"light"}
                onClick={()=>endTurn(mana)}
                >
                  End Turn
                </Button>
              : null}
            </Col>
            <Col>
              <h4 className="text-light" style={{ paddingTop: "10px", paddingBottom: "5px" }}>{log} 
              {log.search("turn") > -1 ? <> received <FontAwesomeIcon size="1x" icon={faTint} className="text-blue"/> {regenMP} {leftover !== null ? `+ ${leftover}` : null}</> : null}</h4>
            </Col>
          </Row>
        </div>
          <Row className="m-0" style={{ minHeight: "270px" }}>
            <Col>
              <div className="p-3 m-auto text-light mb-2" style={{maxWidth:"200px", boxShadow: yourTurn?"0px 0px 15px 15px rgba(255, 174, 0, 0.4)":null,borderRadius:"20px", backgroundColor:"#3e2723"}}>
                <Image roundedCircle src={`/images/cards/${profile.icon}.png`} 
                style={{width:"100px",height:"100px", margin:"0 auto", background: profile.background }}/>
                <h4 className="mt-2">{profile.disname}</h4>
                <div>
                {yourTurn?
                  <div>
                    <h4><FontAwesomeIcon size="1x" icon={faTint} className="text-blue"/> {mana}</h4>
                    {!automated ? <Button className="mt-2" onClick={()=>{
                      gameEnd(false);
                    }} variant="secondary">Concede</Button> : null}
                  </div>
                :null}
                </div>
              </div>
            </Col>
            {Deck(true)}
          </Row>
          {!automated && mana<(deck[0]===undefined || cardUse[0] ? 500:deck[0].cost)&& mana<(deck[1]===undefined || cardUse[1]?500:deck[1].cost)&&
          mana<(deck[2]===undefined || cardUse[2]?500:deck[2].cost) ? (
            null
          ) : (
            <div className="mt-4">
            {!automated ? <h5>Click on your card to attack</h5> : null}
            <h6>Cards attack from left to right</h6>
            <h6>Up to 3 unspent <FontAwesomeIcon size="1x" icon={faTint} className="text-blue"/> is given to the opponent</h6>
            </div>
          )}
      </div>
      }
    </div>
  )
}

export default injectIntl(Game)
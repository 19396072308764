export default function cardHud(name){

    let next
    switch(name){
        case "Boi":
          next={ special: "None", sp: "Just a normal boy" }
          break;
        case "Gurl":
          next={ special: "SANG!", sp: "33% chance to inflict damage of 2x max AP" }
          break;
        case "Zombie":
          next={ special: "Replication", sp: "Heal himself for 3x AP" }
          break;
        case "Aqua":
          next={ special: "Ocean Eye", sp: "Generate 3 mana after attacking" }
          break;
        case "Witch":
          next={ special: "Hidden Book", sp: "50% to heal an ally or herself for AP x3 otherwise increase AP of an ally or herself for 75% of AP" }
          break;
        case "Ghost":
          next={ special: "Hell Arm", sp: "Inflict damage of AP x2" }
          break;
        case "Golem":
          next={ special: "Rock Punch", sp: "Inflict damage of max AP x2" }
          break;
        case "Therapist":
          next={ special: "Therapy", sp: "Heal all allies and herself for AP" }
          break;
        case "Elekt":
          next={ special: "Overload", sp: "Reduce the MP of himself by 1 after attacking" }
          break;
        case "Mage":
          next={ special: "Drain Beam", sp: "Heal himself for damage dealt" }
          break;
        case "Necro":
          next={ special: "Undeady", sp: "Upon dying summons Skeleton" }
          break;
        case "Dragon":
          next={ special: "Fire Breath", sp: "Inflict damage of 75% of AP to all enemies" }
          break;
        case "Wizzard":
          next={ special: "Frozen Orb", sp: "Increase the MP of the enemy by 1 after attacking" }
          break;
        case "Aliados":
          next={ special: "???", sp: "You don't know what they are capable of!" }
          break;
          case "Skeleton":
          next={ special: "None", sp: "Just a normal skeleton" }
          break;
        default:
          break
      }
    return next
}
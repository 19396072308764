import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { Row, Col, Button, Image, FormControl } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar,  faTint, faMedal } from '@fortawesome/free-solid-svg-icons'
import Game from "./Game"
import AiCardReturn from "./data/mobCard"
import BossCardReturn from "./data/bossCard"
import PickCard from "../Craft/PickCard"
import api from "../../../api"
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import CardUI from "./CardUI";
import { Decrypt, EncryptObject } from "../../Work/Module/TaskDecrypt";

var deckSaved
var cardsSaved

const Battle = ({ userData, cardCollection, buffs, profile, point, saveData, startGame, ready }) => {
  const [deck, setDeck] = useState([])
  const [cards, setCards] = useState([])
  const [mobName, setMobName] = useState("")
  const [aiProfile, setAiProfile] = useState({});
  const [modalShow, setModalShow] = useState(false)
  const [editCard, setEditCard] = useState(0)
  const [aiDeck, setAiDeck] = useState([])
  const [battle, setBattle] = useState(false)
  const [alert, setAlert] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [, updateState] = useState();
  const [reward, setReward] = useState({ mob: 3, boss: 1, bossRank: 10 });

  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(()=>{
    let cardsD = [...cardCollection];
    let card1
    let card2
    let card3
    for(let i=0;i<cardsD.length;i++){
      if(cardsD[i].equip === 1){
        card1=cardsD[i]
      }
      if(cardsD[i].equip === 2){
        card2=cardsD[i]
      }
      if(cardsD[i].equip === 3){
        card3=cardsD[i]
      }
    }
    setDeck([card1, card2, card3])
    deckSaved = [card1, card2, card3]
    cardsSaved = [...cardCollection]
    let rank = point.rank
    setCards(cardCollection)
    setMobName(rank<10?"Lu":rank<20?"Luna":rank<30?"Kenta":rank<40?"Annika":rank<60?"Mina":"Nao");
    if(userData.point.reward) setReward({ ...userData.point.reward });
  },[cardCollection, point])

  const deckChange=(cardKey,deckKey)=>{
    for(let i=0;i<cardsSaved.length;i++){
      if(cardsSaved[i].equip === deckKey+1){
        cardsSaved[i].equip = 0
      }
    }
    cardsSaved[cardKey].equip = deckKey+1
    deckSaved[deckKey] = cardsSaved[cardKey]
    setDeck(deckSaved)
    setCards(cardsSaved)
    saveData({ items: { cards: cardsSaved, buffs: buffs } })
    //let userData = { username: user.username, cards: cardsSaved };
    //axios.post(api+"/users/cardEquip", userData)
  }

  const unequip=(num)=>{
    for(let i=0;i<cardsSaved.length;i++){
      if(cardsSaved[i].equip === num){
        cardsSaved[i].equip = 0
      }
    }
    deckSaved[num-1] = undefined
    setDeck(deckSaved)
    setCards(cardsSaved)
    saveData({ items: { cards: cardsSaved, buffs: buffs } })
    forceUpdate()
  }

  const startBattle = (type) =>{
    if(deck[0] || deck[1] || deck[2]){
      if(type === "boss") {
        setAiDeck(BossCardReturn(reward.bossRank));
        setAiProfile({ icon: "Aliados", disname: "Aliados", boss: true, rank: reward.bossRank });
      } else if(type === "mob") {
        setAiDeck(AiCardReturn(point.rank))
        if(reward.mob > -1) {
          let rewardSave = { ...reward, mob: reward.mob - 1 };
          saveData({ point: { reward: rewardSave } });
          setReward(rewardSave);
        }
        setAiProfile({ icon: mobName, disname: mobName, rank: point.rank });
      } else {
        let opponent
        try {
          opponent = Decrypt(inviteCode);
        } catch(err) {
        }
        if(!opponent) return;
        setAiDeck(opponent.deck);
        setAiProfile({ ...opponent.profile, player: true });
      }
      startGame(true)
      setBattle(true)
    } else if(!alert) {
      setAlert(true)
      setTimeout(()=>setAlert(false),2000)
    }
  }

  return (
    <div className="p-2">
      {battle ?
        <Dialog fullScreen open>
          <div className="play-bg" style={{ fontFamily: "Montserrat", textAlign: "center" }}>
            <Game
              iniDeck={deck}
              iniAiDeck={aiDeck}
              point={point}
              profile={profile}
              aiProfile={aiProfile}
              reward={reward}
              gameover={(rank, coin)=>{
                let pointSaved = { coin: point.coin + coin, rank: rank }
                if(aiProfile.boss && coin !== 0) {
                  pointSaved.reward = { ...reward, bossRank: reward.bossRank + 10 };
                  setReward({ ...reward, bossRank: reward.bossRank + 10 });
                }
                saveData({ point: pointSaved });
                // let user = { username: user.username, coin: point.coin + coin, rank: rank};
                // axios.post(api+"/users/gameDone", user)
              }}
              close={rank=>{
                setMobName(rank<10?"Lu":rank<20?"Luna":rank<30?"Kenta":rank<40?"Annika":rank<60?"Mina":"Nao")
                startGame(false)
                setBattle(false)
              }}
            />
          </div>
        </Dialog>
        :
        <div style={{maxWidth:"900px",margin:"auto"}}>
            <h4>
            {point.rank<20?"Bronze": point.rank<40?"Silber": point.rank<60?"Gold":"Diamond"} League <FontAwesomeIcon
              icon={faMedal}
              size="lg"
              style={{fontSize:"20px",marginLeft:"5px",marginTop:"1px",
              color: point.rank<20?"rgb(224, 137, 65)": point.rank<40?"rgb(190, 190, 190)": point.rank<60?"rgb(255, 199, 16)":"rgb(119, 174, 255)"}}
              className="gold"
            /></h4>
            <p>
            <FontAwesomeIcon size="1x" icon={faTint} className="text-blue"/> 
            {" "}{point.rank<10? "1 ~ 4" : point.rank<20? "2 ~ 6" : point.rank<30? "3 ~ 9" : "4 ~ 11"}{" "}
            is generated each turn
            </p>
          <Row className="m-0">
            <Col className="mb-3">
            <h4 className="font-weight-bold"><FormattedMessage id="challenger"/></h4>
            <h6 className="mb-3">Reward available {reward.mob < 1 ? 0 : reward.mob}/3</h6>
              <div
              className="card-round m-auto p-3"
              style={{maxWidth:"300px", color: "black"}}
              >
  
                <Image rounded style={{width:"100%", backgroundColor:"white"}} src={`/images/${mobName}.png`}/>
                <h4 className="font-weight-bold mt-2">{mobName} <FontAwesomeIcon size="1x" icon={faStar} className="text-orange"/>{point.rank}</h4>
                <h6 className="text-muted">Get stars by winning!</h6>
                <Button className="mt-1" variant={"info"} style={{fontSize:"25px"}} onClick={()=>startBattle("mob")}>
                  BATTLE
                </Button>
              </div>
            
            </Col>
            <Col className="mb-3">
            <h4 className="font-weight-bold"><FormattedMessage id="boss"/></h4>
            <h6 className="mb-3">Greater reward by winning</h6>
              <div
              className="card-round m-auto p-3"
              style={{maxWidth:"300px", color: "black"}}
              >
                <Image style={{width:"100%"}} src={`/images/Aliados.png`}/>
                <h4 className="mt-2 font-weight-bold">Aliados <FontAwesomeIcon size="1x" icon={faStar} className="text-orange"/>{reward.bossRank}</h4>
                <h6 className="text-muted">Test your power!</h6>
                <Button 
                className="mt-1" variant={point.rank > 4 ? "info":"secondary"} style={{fontSize:"25px"}} 
                onClick={()=>{if(point.rank > 4) startBattle("boss")}}>
                  {point.rank < 5 ? <>From <FontAwesomeIcon size="1x" icon={faStar} className="text-orange"/> 5</> : "BATTLE"}
                </Button>
              </div>
            </Col>
            <Col className="mb-3">
            <h4 className="font-weight-bold">vs Player</h4>
            <h6 className="mb-3">Experimental game</h6>
              <div
              className="card-round m-auto p-3"
              style={{maxWidth:"300px", color: "black"}}
              >
                <Image rounded style={{width:"100%", backgroundColor:"white"}} src={`/images/cards/Wizzard.png`}/>
                <h4 className="font-weight-bold mt-2">??? <FontAwesomeIcon size="1x" icon={faStar} className="text-orange"/>?</h4>
                <h6 className="text-muted">Play against real players!</h6>
                    <Button className="mt-1" variant={"info"} style={{fontSize:"25px"}} onClick={()=>setOpenInvite(true)}>
                      BATTLE
                    </Button>
              </div>
            
            </Col>
          </Row>
          {alert? <h5>You need to have at least 1 card equipped!</h5>:null}
          {ready ? (
            <div>
            <h2 className="mt-5 mb-3">Your Deck</h2>
            <Row className="m-0 p-3">
              {deck.map((card,key)=>{
                return (
                  <Col key={key} className="mb-3">
                    <div className="m-auto card-game">
                      {card ? (
                        <CardUI card={card} unequip={()=>unequip(key+1)} />
                      ) :
                      <div
                      onClick={()=>{
                        setModalShow(true)
                        setEditCard(key)
                      }}
                      className="pt-4 pb-4 hoverimage"
                      >
                        <h4>No Card</h4>
                        <h6>Click to equip</h6>
                      </div>}
                    </div>
                  </Col>
                )
              })}
            </Row>
            </div>
          ) : null}
        </div>
      }
      <PickCard
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          setEditCard(0)
        }}
        editNum={editCard}
        cards={cards}
        edit={deckChange}
      />
      <Dialog fullWidth open={openInvite} onClose={()=>setOpenInvite(false)} style={{ fontFamily: "Montserrat" }}>
        <DialogContent>
        <h2 className="mb-3">Play against a Real Player!</h2>
          <Button className="mt-1 mr-2" variant={"primary"} onClick={()=>{
            navigator.clipboard.writeText(EncryptObject({ deck, profile: { icon: "cards/" + profile.icon, disname: profile.disname } }))
          }}>
            Copy Invite Code
          </Button>
          <Button className="mt-1" variant={"success"} onClick={async()=>{
            const text = await navigator.clipboard.readText();
            setInviteCode(text);
          }}>
            Paste Invite Code
          </Button>
          <FormControl className="mt-2" placeholder="Invite Code" value={inviteCode} onChange={event=>setInviteCode(event.target.value)}/>
        </DialogContent>
          <DialogActions>
            <Button disabled={!Boolean(inviteCode)} className="mt-1" variant={"info"} style={{fontSize:"25px"}} onClick={()=>{
              startBattle("player");
              setOpenInvite(false);
            }}>
              Start Battle
            </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}

export default Battle;
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

const ChartsPage=props=> {
  const [dataLine] = useState(
    {
      labels: props.labels,
      datasets: [
        {
          label: "Hours worked",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(242, 123, 5, 0.3)",
          borderColor: "rgb(242, 123, 5)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(205, 130,1 58)",
          pointBackgroundColor: "rgb(255, 255, 255)",
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: props.datas
        }
      ]
    }
  )
  
  return (
    <MDBContainer>
      <Line 
        data={dataLine}
        options={{
          responsive: true,
          legend:{
            display:false
          } 
        }} />
    </MDBContainer>
  );
}
  
export default ChartsPage;
import React from "react";
import { Image } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint, faTimes, faHandRock, faHeart, faBolt, faHandPaper, faCheckCircle, faCircle, faLeaf, faQuestion } from '@fortawesome/free-solid-svg-icons'

import rarityReturn from "../Craft/rarityReturn";
import { Tooltip, withStyles } from "@material-ui/core";
import CardHud from "./data/cardHud";

const StyledTooltip = withStyles({
  tooltip: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f5f5f9'
  }
})(Tooltip);


export default function CardUI ({ card, cancellable, unequip, selling, buff }) {
    return (
        <StyledTooltip 
        title={!buff ? (
            <div style={{ padding: "5px", fontFamily: "Montserrat" }}>
              <div style={{ fontSize: "20px", lineHeight: "18px", marginBottom: 7 }}><FontAwesomeIcon size="1x" icon={faBolt} className="text-orange"/> {CardHud(card.name).special}</div>
              <div style={{ fontSize: "16px", lineHeight: "18px", marginBottom: 7 }}>{CardHud(card.name).sp}</div>
              {card.name !== "Aliados" && card.name !== "Boi" && card.name !== "Necromancer" && card.name !== "Skeleton" ? (
                <div style={{ fontSize: "14px", lineHeight: "15px" }}>*Special happens by a 25% chance when attacking</div>
              ) : null}
            </div>
        ) : (
            <div style={{ padding: "5px", fontFamily: "Montserrat", fontSize: "20px" }}>Can be used in Craft</div>
        )}
        >
          <div>
            {unequip || cancellable ? (
                <FontAwesomeIcon icon={faTimes} size="3x"
                style={{position:"absolute",right:"3%",top:0,color:"black",cursor:"pointer",opacity:"0.5"}}
                onClick={()=>{
                    if(unequip) unequip();
                }}/>
            ) : selling || card.equip ? (
                <FontAwesomeIcon size="3x" icon={card.equip ? faHandPaper : card.selling? faCheckCircle : faCircle} 
                style={{ 
                    position:"absolute", right:"3%", top:"2%", cursor:"pointer", color:"black",opacity:"0.7", borderRadius:"48%",
                    backgroundColor: !card.equip ? "white": null
                }}
                />
            ) : null}
            {!buff ? (
                <>
                    <h5 className="text-left">{card.name} (+{card.buff})</h5>
                    <Image
                    rounded
                    className={rarityReturn(card.name)}
                    src={`/images/cards/${card.name}.png`}
                    style={{width: "100%",height: "auto"}}
                    />
                    <p />
                    <table className="mb-2" style={{width:"100%",color:"rgb(50,50,50)"}}>
                    <tbody>
                        <tr>
                        <td width="10%"><FontAwesomeIcon size="1x" icon={faHeart} className="text-red"/></td><td width="30%">{card.hp}</td>
                        <td width="10%"><FontAwesomeIcon size="1x" icon={faHandRock} className="text-yellow"/></td><td width="50%">{card.ap}-{card.ap2}</td>
                        </tr>
                        <tr>
                        <td><FontAwesomeIcon size="1x" icon={faTint} className="text-blue"/></td><td>{card.cost}</td>
                        <td><FontAwesomeIcon size="1x" icon={faBolt} className="text-orange"/></td>
                        <td>
                            <p style={{fontSize:"13px",margin:"0", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}> {CardHud(card.name).special}</p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </>
            ) : (
                <>
                    {cancellable ? <h5 className="text-left">{card.name}</h5> : null}
                    <Image
                        rounded
                        className={card.fname}
                        src={`/images/cards/${card.name.search("Gems") > -1 ? "Gems" : card.name}.png`}
                        style={{
                        width: "100%",
                        }}
                    />
                    <p/>
                    <h6 style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}> {card.fname} {card.name}</h6>
                    <div style={{fontSize: "15px",color:"rgb(50,50,50)"}}>
                        <FontAwesomeIcon size="1x"
                        icon={card.name==="Health Potion"?faHeart:card.name==="Power Potion"?faHandRock:card.name==="Lucky Charm"?faLeaf:card.name==="Oblivion"?faQuestion : null}
                        className={card.name==="Health Potion"?"text-red":card.name==="Power Potion"?"text-yellow":card.name==="Lucky Charm"?"text-green":"text-brown"}
                        /> {card.power}
                    </div>
                </>
            )}
          </div>
        </StyledTooltip>
    )
}
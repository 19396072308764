import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import axios from "axios"
import api from "../../api";
import { Encrypt } from "../Work/Module/TaskDecrypt"


export default function ChatComponent(props) {
  const [added, setAdded] = React.useState(false)
  const linkify =(inputText)=> {
    let arr = inputText.split("PVT153")
    if(arr.length === 2) {
      return (
        <div className="p-1">
          <h5 style={{ color: "rgb(50,50,50)", fontWeight:"600" }}>Task Request <FontAwesomeIcon icon={faFlag}
          style={{color:`rgb(${arr[1] === "Medium"?"89, 197, 56": arr[1] === "Low"?"112, 112, 112":"201, 82, 82"})`}} /></h5>
          <div style={{background: "white", padding: "5px", borderRadius: "5px"}}>
            <h6 style={{ color: "gray" }}>Description</h6>
            {arr[0]}
          </div>
          {props.chat[1] === "R"?
            <div>
            {added?
            <button className="btn-green mt-1" style={{fontSize: "15px", background: "rgb(120,120,120)", color: "white"}}>Added!</button>
            :<button className="btn-green mt-1" style={{fontSize: "15px"}} onClick={()=> {
              let tas = {description: arr[0], priority: arr[1]}
              props.addtolist(tas)
              let info = { username: props.username, task: Encrypt(JSON.stringify(tas)) }
              axios.post(api+"/users/taskadd", info)
              setAdded(true)
            }}>Add to list</button>}
            </div>
          :null}
        </div>
      )
    } else {
      var wordarr = inputText.split(' ')
      return wordarr.map((text,key) => {
        let lineBreak = text.indexOf("\n");
        if(text.substring(0,8)==="https://"||text.substring(0,7)==="http://"){
          return <div key={key}><a href={text} rel="noopener noreferrer" target="_blank">{text}</a></div>
        } else if(lineBreak > -1){
          let lt = text.replace(/\n/g, '\nOEF3edDef2334dfg\n');
          let lbarr = lt.split("\n")
          return lbarr.map((lb,key)=>{
            if(lb==="OEF3edDef2334dfg") return <br key={key}/>
            else return lb+" "
          })
        } else return text+" "
      })
    }
  }

  return (
    <div
      style={props.style}
    ><div style={{width:"100%",overflowWrap: "break-word"}}>
      {linkify(props.chat[0])}
      </div>
    {!props.timeline? <div style={{fontWeight: "normal", color: "gray", margin:"0", textAlign: "right"}}>{props.chat[2]}</div>:null}
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react"
import axios from "axios"
import api from "../../api"
import { Decrypt, Encrypt, EncryptObject } from "./Module/TaskDecrypt"
import { FormattedMessage } from "react-intl"

import { Table, Button, Modal, FormControl, Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag, faTrash, faEdit, faCheckCircle, faList, faShare } from '@fortawesome/free-solid-svg-icons'

var editKey

function EditModal(props) {
    const [description, setDescription] = useState(props.task.description)
    const [priority, setPriority] = useState(props.task.priority)
    const [top, setTop] = useState(true)
    const [sendToDis, setSendToDis] = useState("Choose")
    const [sendTo, setSendTo] = useState("")

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
        style={{ fontFamily: "Montserrat" }}
      >
        <Modal.Body>
            <h5 className="font-weight-bold"><FormattedMessage id="priority"/></h5>
            <Button
            className="m-2"
                variant={priority==="Low"?"secondary":"light"}
                onClick={()=>setPriority("Low")}
            >
                <FormattedMessage id="Low"/>
            </Button>
            <Button
            className="m-2"
            variant={priority==="Medium"?"success":"light"}
            onClick={()=>setPriority("Medium")}
            >
                <FormattedMessage id="Medium"/>
            </Button>
            <Button
            className="m-2"
            variant={priority==="High"?"danger":"light"}
            onClick={()=>setPriority("High")}
            >
                <FormattedMessage id="High"/>
            </Button>
            <h5 className="font-weight-bold mt-3">
                <FormattedMessage id="description"/>
            </h5>
            
            <FormControl
                as="textarea"
                rows="5"
                value={description}
                onChange={event=>setDescription(event.target.value)}
            />
            {!props.share ? <h6 className="mt-1 mb-3 text-right mr-3">
                Add to the <Button variant="warning" style={{ fontSize: "13px", padding: "1px 5px"}} 
                onClick={()=> setTop(!top)}>
                {top? "Top": "Bottom"}</Button>
            </h6>
            :
            <h6 className="mt-2">Share with...
                <Dropdown className="mt-2 mb-3">
                    <Dropdown.Toggle variant="warning">
                        {sendToDis}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {props.friends.map((friend, key)=>{   
                            if(friend.username !== "admin" ) return <Dropdown.Item onClick={()=>{
                                setSendTo(friend.username)
                                setSendToDis(friend.disname)
                            }} key={key}>{friend.disname} ({friend.username})</Dropdown.Item>
                            else return ""
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </h6>
            }
            <Button
            className="mr-3"
            onClick={()=>{
                if(props.share && description && sendTo ) {
                    let copy = `${description}PVT153${priority}`
                    props.sharing(copy, sendTo)
                    props.onHide()
                } else if(description && !props.share) {
                    let task = {description: description, priority: priority}
                    if(editKey === undefined) props.create(task, top)
                    else props.edit(task)
                    props.onHide()
                }
            }}>
                <FormattedMessage id={props.share? 'share': "save"}/>
            </Button>
            <Button variant="danger" onClick={()=> props.onHide()}>
                Cancel
            </Button>
        </Modal.Body>
      </Modal>
    );
}

export default function TaskList(props){
    const [draggable, setDraggable] = useState(false)
    const [dragged, setDragged] = useState(undefined)
    const [dragging, setDragging] = useState(undefined)
    const [dropAt, setDropAt] = useState(undefined)
    const [tasks, setTasks] = useState([])
    const [editShow, setEditShow] = useState(false)
    const [editTask, setEditTask] = useState({})
    const [share, setShare] = useState(false)
    const [, updateState] = React.useState();

    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(()=>{
        // let taskarr=[]
        // for(let i=0;i<props.tasks.length;i++){
        //   let taskdecrypt = Decrypt(props.tasks[i].task)
        //   taskdecrypt.done = props.tasks[i].done
        //   taskarr.push(taskdecrypt)
        // }
        let value = "; " + (document.cookie || "");
        let encrypted = value.split("; Tasks=")[1] ? value.split("; Tasks=")[1].split(";")[0] : "";
        if(encrypted) {
            const data = Decrypt(encrypted);
            setTasks(data);
        }
    },[])

    const saveToCookie = (data) => {
        document.cookie = `Tasks=${EncryptObject(data)}; expires=Thu, 01 Jan ${new Date().getFullYear() + 5} 00:00:00 UTC; path=/;`;
    }

    useEffect(()=>{
        if(props.addTask.description) {
            let tempTasks = [...tasks]
            tempTasks.unshift({ description: props.addTask.description, priority: props.addTask.priority, done: false })
            setTasks(tempTasks)
            props.empty()
        }
    },[props.addTask])

    const taskCreate = (task, top) => {
        let taskArr = task
        taskArr.done = false
        let newTasks;
        if(top) newTasks = [taskArr, ...tasks];
        else newTasks = [...tasks, taskArr];

        setTasks(newTasks);
        saveToCookie(newTasks);
        // let arr = []
        // for(let i=0; i<tasks.length; i++){
        //     if(top && i === 0 ){
        //         arr.push({task:Encrypt(JSON.stringify(task)), done: false})
        //     }
        //     arr.push({task:Encrypt(JSON.stringify( {description: tasks[i].description, priority: tasks[i].priority} )), done:tasks[i].done})
        //     if(!top && i === tasks.length - 1){
        //         arr.push({task:Encrypt(JSON.stringify(task)), done: false})
        //     }
        // }
        // let info = { username: props.username, tasks: arr };

        // axios.post(api+"/users/taskmove", info)
    }

    const taskEdit = task => {
        let taskEdited = task
        let tasksArr = tasks
        taskEdited.done = false
        if(editKey !== undefined) tasksArr[editKey] = taskEdited;
        else tasksArr.push(taskEdited);
        setTasks(tasksArr);
        saveToCookie(tasksArr);
        // let taskencrypt =  Encrypt(JSON.stringify(task))
        // let info = { username: props.username, place: "tasks." + editKey, task: taskencrypt }
        // axios.post(api+"/users/taskedit", info)
    }
    
    const taskDrop =()=>{
        if(dragged!==dropAt && dropAt!==undefined){
            let taskArr = []
            let changeArr = []
            let add = 0
            for(let i=0; i<tasks.length; i++){
                if(i===tasks.length-1&&dropAt<dragged) break
                let additional
                if(i===dragged){
                    add ++
                }

                if(i===dropAt){
                    taskArr.push({task:Encrypt(JSON.stringify({description:tasks[dragged].description, priority: tasks[dragged].priority})),done:tasks[dragged].done})
                    changeArr.push(tasks[dragged])
                    if(dropAt<dragged) additional=true
                    else add --
                } else additional = true

                if(additional){
                    taskArr.push({task:Encrypt(JSON.stringify({description:tasks[i+add].description, priority: tasks[i+add].priority})),done:tasks[i+add].done})
                    changeArr.push(tasks[i+add])
                }
            }
            // let info = {username: props.username, tasks: taskArr}
            // axios.post(api+"/users/taskmove", info).then(console.log("Moved"))

            setTasks(changeArr)
            saveToCookie(changeArr);
        }
        setDragged(undefined)
        setDropAt(undefined)
        setDragging(false)
    }

    const sharing = (data, sendTo) => {
        axios.post(`${api}/friends/send`, {
            username: sendTo,
            owner: props.username,
            content: data,
            last: undefined
        }).then(()=> {
            props.sendMessage(sendTo)
            props.chatUpdate()
        })
    }

    return(
        <div className="card-round mb-3">
            <div className="card-top" style={{backgroundColor:"#ff821c"}}>
                Tasks
            </div>
            <div className="card-bottom" style={{padding:0}}>
            <div className="text-right pr-4 pt-2">
                <Button
                    className="font-weight-bold"
                    variant="dark"
                    onClick={()=>{
                        editKey = undefined
                        setEditTask({description:"", priority: "Medium"})
                        setEditShow(true)
                    }}
                    >
                    + NEW
                </Button>
            </div>
            <Table hover responsive className="mt-3">
                <tbody>
                    {tasks.map((task,key) => {
                        const spotUrl = desc => {
                            var wordarr = desc.split(' ')
                            return wordarr.map((text,key) => {
                    
                            let lineBreak = text.indexOf("\n");
                            if(text.substring(0,8)==="https://"||text.substring(0,7)==="http://"){
                            return <div key={key}><a href={text} rel="noopener noreferrer" target="_blank">{text}</a></div>
                            } else if(lineBreak > -1){
                                let lt = text.replace(/\n/g, '\nOEF3edDef2334dfg\n');
                                let lbarr = lt.split("\n")
                                return lbarr.map((lb,key)=>{
                                if(lb==="OEF3edDef2334dfg") return <br key={key}/>
                                else return lb+" "
                                })
                            }
                            else return text+" "
                            })
                        }
                        return(
                            <tr key={key}
                                draggable={draggable}
                                style={{textAlign:"left",backgroundColor:dragged===key?"rgb(230,230,230)":dropAt===key?"rgb(255,248,235)":null}}
                                onDragStart={()=>{
                                    setDragged(key)
                                    setDragging(true)
                                }}
                                onDragEnd={()=>{
                                    taskDrop()
                                }}
                                onDragOver={e=>{
                                    e.preventDefault()
                                }}
                                onDragEnter={()=>{
                                    if(dragging) setDropAt(key)
                                }}
                            >
                                <td width="5px">
                                    <FontAwesomeIcon
                                        icon={faList}
                                        style={{cursor:"move", fontSize:"22px", color:"rgb(20,20,20)", marginTop:"3px"}}
                                        onMouseEnter={()=>setDraggable(true)}
                                        onMouseLeave={()=>setDraggable(false)}
                                    />
                                </td>
                                <td width="5px">
                                    <FontAwesomeIcon
                                        icon={faCheckCircle}
                                        className="mt-1 p-0"
                                        style={{color:task.done?"#46c0e6":"gray", cursor:"pointer", fontSize:"22px"}}
                                        onClick={()=>{
                                            let change = tasks
                                            change[key].done = !task.done
                                            setTasks(change)
                                            saveToCookie(change);
                                            // let info = {username: props.username, value: task.done, num: key}
                                            // axios.post(api+"/users/taskdone", info)
                                            forceUpdate()
                                        }}
                                    />
                                </td>
                                <td width="5px">
                                    <FontAwesomeIcon
                                        icon={faFlag}
                                        className="mt-2"
                                        style={{color:`rgb(${task.priority==="Medium"?"89, 197, 56":task.priority==="Low"?"112, 112, 112":"201, 82, 82"})`}}
                                    />
                                </td>
                                <td className="text-left notbig" style={{ lineHeight: 2 }}>
                                    <div style={{color:task.done?"gray":"black",paddingTop:"2px"}}>
                                        {spotUrl(task.description)}
                                    </div>
                                </td>
                                <td width="10px" className="ml-auto">
                                    <div>
                                        <FontAwesomeIcon
                                        className="hoverbadge"
                                        icon={faEdit}
                                        onClick={()=>{
                                            editKey = key
                                            setEditTask(task)
                                            setEditShow(true)
                                        }}
                                        />
                                        <br/>
                                        {/* <FontAwesomeIcon
                                        className="hoverbadge"
                                        icon={faShare}
                                        onClick={()=>{
                                            setShare(true)
                                            setEditTask(task)
                                            setEditShow(true)
                                        }}
                                        />
                                        <br/> */}
                                        <FontAwesomeIcon
                                        className="hoverbadge"
                                        icon={faTrash}
                                        onClick={()=>{
                                            let change = tasks
                                            change.splice(key,1)
                                            setTasks(change);

                                            saveToCookie(change);
                                            forceUpdate()
                                            // let info = {username: props.username, pos: key}
                                            // axios.post(api+"/users/taskdelete", info)
                                        }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
                {!tasks.length?
                <tfoot>
                        <tr>
                            <td colSpan="4">
                                <h4 className="font-weight-bold pt-4 pb-3 text-center">No tasks... Let's make one!</h4>
                                <Button
                                    className="font-weight-bold mr-2 mb-3"
                                    variant="success"
                                    onClick={()=>{
                                        editKey = undefined
                                        setEditTask({description:"", priority: "Medium"})
                                        setEditShow(true)
                                    }}
                                    >
                                    Sure!
                                </Button>
                            </td>
                        </tr>
                        <tr style={{opacity:"0.7"}}>
                            <td width="10px">
                                <FontAwesomeIcon
                                    icon={faList}
                                    style={{fontSize:"22px", color:"rgb(20,20,20)", marginTop:"3px"}}
                                />
                            </td>
                            <td width="10px">
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{color:"gray", cursor:"pointer", fontSize:"22px", marginTop:"3px"}}
                                />
                            </td>
                            <td width="10px">
                                <FontAwesomeIcon
                                    icon={faFlag}
                                    style={{color:"rgb(89, 197, 56)"}}
                                    className="mt-2"
                                />
                            </td>
                            <td className="text-left notbig">
                                <div style={{paddingTop:"2px"}}>
                                    This is an example task! Login to Motimanager! <a href="https://motimanager.com">https://motimanager.com</a>
                                </div>
                            </td>
                            <td width="10px" className="ml-auto">
                                <div>
                                    <FontAwesomeIcon
                                    icon={faEdit}
                                    />
                                    <br/>
                                    <FontAwesomeIcon
                                    icon={faTrash}
                                    />
                                </div>
                            </td>
                        </tr>
                </tfoot>
                :null}
            </Table>
            </div>
            {editShow?
            <EditModal
                show={editShow}
                onHide={()=> {
                    setShare(false)
                    setEditShow(false)
                }}
                create={taskCreate}
                edit={taskEdit}
                share={share}
                sharing = {sharing}
                task={editTask}
                friends={props.friends}
            />
            :null}
        </div>
    )   
}
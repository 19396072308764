import React, { useState } from "react";
import axios from "axios";
import { injectIntl } from "react-intl";
import { Button, Modal, Col, Row, Image } from "react-bootstrap"
import Chest from "./chestReturn"
import Inventory from "./Inventory"
import api from "../../../api"
import gemReturn from "./gemReturn"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faGem, faSync, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import CardUI from "../Battle/CardUI";

var sellSaved = []
var sellSaved2 = []

const bluePrice = 1000

function ItemReceived(props) {
  let name = props.item.name
  if(props.item.fname) name = props.item.fname + " " + name
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="text-center" 
    >
      <div className="play-bg" style={{ color:"white", padding:"20px", fontFamily:"Montserrat" }}>
        {!props.alert?
        <div>
          <h3 className="pb-3">You Got</h3>
          <div className="card-game border-orange m-auto">
            {props.item.value ? (
              <>
              <div style={{width:"100%", height: "150px", background: props.item.value}}/>
              <h5 className="mt-2">{name}</h5>
              </>
            ) : (
              <CardUI card={props.item} buff={props.item.hp ? false : true} />
            )}
          </div>
        </div>
        :<h3>You don't have enough Coins/Gems!</h3>}
        <p/>
        <Button variant="info" onClick={props.onHide}>OK</Button>
      </div>
    </Modal>
  );
}

var cardsSaved = []
var buffsSaved = []

const Shop =  ({ user, point, saveData, cards, buffs, setCards, setBuffs, box, custom, setBox }) =>{
  const [modalShow, setModalShow] = useState(false)
  const [alert, setAlert] = useState(false)
  const [item, setItem] = useState({})
  const [selling, setSelling] = useState(false)
  const [gemTrade, setGemTrade] = useState(0)
  const [trade, setTrade] = useState(0)

  cardsSaved = cards
  buffsSaved = buffs
  
  const buy = event => {
    if (
      (point.coin >= bluePrice && event === "orange") ||
      (point.gem >= 15 && event === "pink") ||
      (point.gem >= 10 && event === "green")
    ) { 
      let takecoin = bluePrice
      let takegem = 0
      if(event==="pink") {takecoin=0; takegem=15}
      if(event==="green") {takecoin=0; takegem=10}
      let chest = Chest(event)
      if(chest[1] === "items.collection" && chest[0].name !== "Wall Color") {
        let fil = box.furnitures.filter(a=> a.name === chest[0].name)
        if(fil.length) {
          chest[0]={name: "2 Gems"}; chest[1] = "point.gem"; chest[2] = 2
        }
      }
      let newBox;
      if(chest[1] === "items.cards") {
        cardsSaved.push(chest[0])
        setCards(cardsSaved)
      } else if(chest[1] === "items.collection") {
        const { name, value } = chest[0];
        if(name === "Wall Color") newBox = { ...box, backgrounds: [...box.backgrounds, value] };
        else newBox ={ ...box, furnitures: [...box.furnitures, name] };
        setBox(newBox);
      } else if(chest[1] === "items.buffs") {
        buffsSaved.push(chest[0])
        setBuffs(buffsSaved)
      }
      const forSave = { 
        point: { coin: point.coin-takecoin, gem: point.gem-takegem+chest[2], rank: point.rank}, items: { cards: cardsSaved, buffs: buffsSaved } 
      };
      if(newBox) {
        forSave.house = { custom: custom, box: newBox };
      }
      saveData(forSave);
      setAlert(false)
      setItem(chest[0])
      //axios.post(api+"/users/buy", info)
    } else setAlert(true)
    setModalShow(true)
  };

  return (
    <div className="pl-3 pr-3">
      <div className="card-round text-dark" style={{margin:"30px auto",maxWidth:"1200px",padding:"20px"}}>
        <Row>
          <Col>
            <div className="m-auto pb-3" style={{width:"220px"}}>
              <Image src="/images/shopper.png" style={{width: "100%", marginBottom: "10px"}}/>
              <h3>Trader</h3>
              {selling?
              <h6 className="text-muted">
                Nice! What ya got!?
              </h6>:
              <h6 className="text-muted">
                Got some items you don't need?
              </h6>}
              {!selling?<Button className="mt-2" variant="warning"
                onClick={()=>{
                  setSelling(true)
                }} style={{fontSize:"25px"}}>
                 SELL
              </Button>:
              <h4 className="mt-3">
              Select items! <FontAwesomeIcon icon={faArrowDown}/>
              </h4>}
            </div>
          </Col>
          {!selling?
          <Col className="pb-2">
            <div className="card-round p-4 text-light m-auto" style={{width:"250px", backgroundColor:"rgb(35, 40, 112)"}}>
              <Image rounded src="/images/orange.png" style={{width: "100%", marginBottom: "20px"}}/>
              <h5>Blue Box</h5>
              <h6>Ordinary box but has a chance to get anything!</h6>
              <Button variant="dark" onClick={()=>buy("orange")} style={{fontSize:"25px"}}>
                <FontAwesomeIcon size="sm" icon={faCoins} className="text-yellow"/> {bluePrice}
              </Button>
            </div>
          </Col>
          :<Col>
            <div className="pt-3">
              <h3>Trading...</h3>
              <FontAwesomeIcon size="6x" className="mt-4 mb-4 text-muted" icon={faSync}/>
              <h4 className="mt-3 mb-2">
                You will get <FontAwesomeIcon icon={faGem} className="text-blue"/> {gemTrade}
              </h4>
              <h5 className="text-muted mb-3">{trade} items were selected</h5>
              <Button variant="danger" style={{fontSize:"22px"}}
                onClick={()=>{
                let arr = []
                for(let i = 0; i < sellSaved.length; i++){
                  if(!sellSaved[i].selling) arr.push(sellSaved[i])
                }
                let arr2 = []
                for(let i = 0; i < sellSaved2.length; i++){
                  if(!sellSaved2[i].selling) arr2.push(sellSaved2[i])
                }
                setCards(arr)
                setBuffs(arr2)
                let data = { username: user.username, gem: point.gem + gemTrade, cards: arr, buffs: arr2 }
                if(user.username !== "demo") axios.post(api+"/users/itemdelete", data)

                saveData({
                  point: {coin: point.coin, gem: point.gem + gemTrade, rank: point.rank},
                  items: { cards: arr, buffs: arr2 }
                })

                setGemTrade(0)
                setTrade(0)
                setSelling(false)
              }}>
                Confirm to Trade
              </Button>
              <br/>
              <Button
              className="mt-2"
              variant="secondary"
              onClick={()=>{
                setGemTrade(0)
                setTrade(0)
                setSelling(false)
              }}>
                Cancel
              </Button>
            </div>
          </Col>}
          {!selling?
          <Col className="pb-2">
            <div className="card-round p-4 text-light m-auto" style={{width:"250px",backgroundColor:"rgb(112, 35, 73)"}}>
              <Image src="/images/pink.png" style={{width: "100%", marginBottom: "20px"}}/>
              <h5>Pink Box</h5>
              <h6>Special box that has a guaranteed rare item!</h6>
              <Button variant="dark" onClick={()=>buy("pink")} style={{fontSize:"25px"}}>
                <FontAwesomeIcon size="sm" icon={faGem} className="text-blue"/> 15
              </Button>
            </div>
          </Col>
          :null}
          {!selling?
          <Col className="pb-2">
            <div className="card-round p-4 text-light m-auto" style={{width:"250px",backgroundColor:"rgb(10, 105, 73)"}}>
              <Image src="/images/green.png" style={{width: "100%", marginBottom: "20px"}}/>
              <h5>Green Box</h5>
              <h6>Box of decorations for your house!</h6>
              <Button variant="dark" onClick={()=>buy("green")} style={{fontSize:"25px"}}>
                <FontAwesomeIcon size="sm" icon={faGem} className="text-blue"/> 10
              </Button>
            </div>
          </Col>
          :null}
        </Row>
      </div>
      <h2 className="mt-5 mb-3">Cards</h2>
      <Inventory
      cards={cards}
      buffs={buffs}
      selling={selling}
      select={(arr,arr2)=>{
        sellSaved = arr
        sellSaved2 = arr2
        let temp = []
        for(let i=0; i<arr.length; i++){
          if(arr[i].selling) temp.push(arr[i])
        }
        for(let i=0; i<arr2.length; i++){
          if(arr2[i].selling) temp.push(arr2[i])
        }
        setGemTrade(gemReturn(temp))
        setTrade(temp.length)
      }}
      />
      <ItemReceived
        show={modalShow}
        onHide={() => setModalShow(false)}
        item={item}
        alert={alert}
      />
    </div>
  );
}

export default injectIntl(Shop);
